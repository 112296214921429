import {ValidationErrorResponse} from "../services/types";

const extractValidationErrors = (response: ValidationErrorResponse) : string[] => {
    if (!(response.status === 'error' && response.type === 'validation' && response.validations)) {
        return []
    }

    const validations = response.validations;
    const errors = [];

    for (const field in validations) {
        if (validations.hasOwnProperty(field)) {
            errors.push(...validations[field]); // Spread the array of errors
        }
    }

    return errors;
}

export {
    extractValidationErrors
}