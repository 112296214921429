// @ts-ignore
import React, { useState, useEffect } from 'react';

interface ImageRendererProps {
    file: File | null;
    width?: number;
    height?: number;
    alt?: string;
    className?: string;
    style?: React.CSSProperties;
}

const ImageRenderer: React.FC<ImageRendererProps> = ({
                                                         file,
                                                         width = 100,
                                                         height = 100,
                                                         alt = 'Image Preview',
                                                         className,
                                                         style,
                                                     }) => {
    const [imageUrl, setImageUrl] = useState<string | null>(null);

    useEffect(() => {
        let objectUrl: string | null = null;

        if (file && file.type.startsWith('image/')) {
            objectUrl = URL.createObjectURL(file);
            setImageUrl(objectUrl);
        } else {
            setImageUrl(null);
        }

        return () => {
            if (objectUrl) {
                URL.revokeObjectURL(objectUrl);
            }
        };
    }, [file]);

    if (imageUrl) {
        return (
            <img
                src={imageUrl}
                alt={alt}
                width={width}
                height={height}
                className={className}
                style={style}
            />
        );
    } else {
        return null; // Or you could render a placeholder if no image
    }
};

export default ImageRenderer;