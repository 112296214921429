const primaryColor = "#eb4528"
const activeColor = "#ffffff"

const UnEvenBoxes = ({ active, ...props }) => {
    return (
        <svg
            fill="none"
            strokeWidth="1.19px"
            strokeLinejoin="round"
            strokeLinecap="round"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 15 90.45 75"
            {...props}
        >
            <rect
                x="47.55"
                y="33.13"
                width="17.19"
                height="27.72"
                rx="2.64"
                ry="2.64"
                stroke={active ? activeColor : primaryColor}
            />
            <rect
                x="47.55"
                y="66.49"
                width="17.19"
                height="6.89"
                rx="1.97"
                ry="1.97"
                stroke={active ? activeColor : primaryColor}
            />
            <rect
                x="25.71"
                y="33.13"
                width="17.19"
                height="15.94"
                rx="1.87"
                ry="1.87"
                stroke={active ? activeColor : primaryColor}
            />
            <rect
                x="25.71"
                y="54.62"
                width="17.19"
                height="19.43"
                rx="2.64"
                ry="2.64"
                stroke={active ? activeColor : primaryColor}
            />
        </svg>
    );
};

const Grid = ({active, ...props}) => {
    return <svg fill={active ? activeColor : primaryColor}
                strokeWidth={"1.19px"}
                strokeLinejoin={"round"}
                strokeLinecap={"round"}
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 15 90.45 75"
                {...props}
            >
        <rect className="cls-1" x="21.76" y="30.79" width="17.19" height="15.94" rx="1.87" ry="1.87"/>
        <rect className="cls-1" x="21.76" y="54.05" width="17.19" height="15.94" rx="1.87" ry="1.87"/>
        <rect className="cls-1" x="44.26" y="30.79" width="17.19" height="15.94" rx="1.87" ry="1.87"/>
        <rect className="cls-1" x="44.26" y="54.05" width="17.19" height="15.94" rx="1.87" ry="1.87"/>
    </svg>
}

const AccountManager = ({active, ...props}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 15 90.45 75"
        {...props}
    >
        <circle cx="40.32" cy="33.62" r="11.86" fill={active ? activeColor : primaryColor} />
        <ellipse
            cx="40.32"
            cy="60.35"
            rx="18.25"
            ry="11.86"
            fill="none"
            stroke={active ? activeColor : primaryColor}
            strokeWidth="1.19"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M58.57,29.03c4.28,0,7.74,3.47,7.74,7.74s-3.47,7.74-7.74,7.74"
            fill="none"
            stroke={active ? activeColor : primaryColor}
            strokeWidth="1.74"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M58.57,49.58c8.68,0,15.72,4.57,15.72,10.22s-7.04,10.22-15.72,10.22"
            fill="none"
            stroke={active ? activeColor : primaryColor}
            strokeWidth="1.02"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const SupportPhone = ({active,  ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 15 90.45 75"
        {...props}
    >
        <line
            x1="32.44"
            y1="24.41"
            x2="38.54"
            y2="10.76"
            fill="none"
            stroke={active ? activeColor : primaryColor}
            strokeWidth="2.3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <line
            x1="35.49"
            y1="26.78"
            x2="38.72"
            y2="22.41"
            fill="none"
            stroke={active ? activeColor : primaryColor}
            strokeWidth="2.3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <line
            x1="37.72"
            y1="30.46"
            x2="48.83"
            y2="20.59"
            fill="none"
            stroke={active ? activeColor : primaryColor}
            strokeWidth="2.3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M26.85,25.52s9.74,11.9,10.17,13.85-3.46,6.71-3.03,9.74,11.04,11.04,12.12,11.47,5.19-1.95,6.93-2.81,12.99,8.87,14.29,11.04-6.06,11.04-20.24,7.14c-14.18-3.9-27.6-24.46-29.55-30.52s1.29-13.33,3.67-16.14,5.64-3.77,5.64-3.77Z"
            fill="none"
            stroke={active ? activeColor : primaryColor}
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const SettingsCog = ({ active, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 15 90.45 75"
        {...props}
    >
        <path
            d="M69.08,49.19c0,.65-.08,1.31-.15,1.94s-6,1.17-6.15,1.76c-.36,1.47-.92,2.86-1.67,4.13-.3.52,3.56,4.95,3.2,5.43-.43.58-1.07,1.6-1.58,2.11-.41.41-1.19.97-1.64,1.33-.49.4-4.69-3.8-5.23-3.47-1.27.79-2.67,1.4-4.16,1.8-.58.15-.4,3.6-.9,5.05-.56,1.61-2.35,1.39-3.12,1.39-.55,0-2.42.15-2.95-1.12-.74-1.75-.14-5.09-.84-5.27-1.52-.38-2.95-.99-4.26-1.78-.32-.2-4.97,3.24-5.27,3.02-.78-.56-1.07-.86-1.74-1.55-.48-.5-.86-1.3-1.27-1.86-.33-.44,3.19-4.61,2.91-5.09-.76-1.3-1.23-2.33-1.59-3.83-.13-.56-5.83-1.1-5.9-1.68-.08-.62-.28-1.99-.28-2.63,0-.53-.05-1.47,0-1.98.06-.61,5.91-.48,6.04-1.08.37-1.65.99-3.2,1.84-4.61.33-.55-3.53-4.56-3.13-5.07.71-.9,2.55-2.65,3.44-3.38.6-.49,4.41,3.48,5.08,3.08,1.24-.74,2.59-1.31,4.02-1.68.47-.12,1.23-6.31,1.72-6.39.81-.13,1.36-.3,2.21-.3.75,0,1.84.17,2.56.27s1.06,6.32,1.74,6.51c1.32.38,2.56.93,3.71,1.62.55.33,5.2-3.73,5.71-3.33s1.15.96,1.61,1.43c.35.35,1.14,1.23,1.46,1.61.47.58-3.83,4.99-3.45,5.64.74,1.23,1.31,2.57,1.69,3.99.13.5,6.14.95,6.22,1.47.13.8.15,1.68.15,2.52Z"
            fill="none"
            stroke={active ? activeColor : primaryColor}
            strokeWidth="1.19"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <circle cx="47.68" cy="49.19" r="7.63" fill={active ? activeColor : primaryColor}
        />
    </svg>
);

const ProfileSettings = ({ active, ...props }) => {
    return (
        <svg
            fill={active ? activeColor : primaryColor}
            strokeWidth="1.31px"
            strokeLinejoin="round"
            strokeLinecap="round"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 15 90.45 75"
            {...props}
        >
            <circle cx="38.69" cy="28.84" r="13.13" fill={active ? activeColor : primaryColor} />
            <path
                d="M38.69,71.56c-11.16,0-20.2-5.88-20.2-13.13s9.05-13.13,20.2-13.13"
                fill="none"
                stroke={active ? activeColor : primaryColor}
            />
            <g>
                <path
                    d="M78.53,58.53c0,.51-.06,1.02-.12,1.51s-4.68.92-4.79,1.37c-.28,1.14-.72,2.23-1.3,3.22-.24.4,2.77,3.85,2.49,4.23-.34.45-.84,1.24-1.23,1.64-.32.32-.93.75-1.28,1.04-.38.31-3.65-2.96-4.08-2.7-.99.62-2.08,1.09-3.24,1.4-.45.12-.31,2.81-.7,3.93-.43,1.26-1.83,1.09-2.43,1.09-.43,0-1.89.12-2.3-.87-.58-1.36-.11-3.97-.66-4.11-1.18-.3-2.3-.77-3.32-1.39-.25-.15-3.87,2.53-4.11,2.36-.61-.44-.84-.67-1.36-1.21-.37-.39-.67-1.01-.99-1.45-.25-.35,2.48-3.59,2.27-3.97-.59-1.01-.96-1.81-1.24-2.98-.1-.44-4.55-.85-4.6-1.31-.06-.48-.22-1.55-.22-2.05,0-.41-.04-1.14,0-1.55.05-.48,4.6-.38,4.71-.84.29-1.29.78-2.49,1.43-3.59.26-.43-2.75-3.56-2.44-3.95.55-.7,1.99-2.07,2.68-2.63.47-.38,3.44,2.71,3.96,2.4.96-.57,2.02-1.02,3.13-1.31.37-.1.96-4.91,1.34-4.98.63-.1,1.06-.23,1.72-.23.58,0,1.43.13,1.99.21s.83,4.92,1.36,5.08c1.03.29,2,.72,2.89,1.26.43.26,4.06-2.9,4.45-2.6s.9.75,1.26,1.11c.27.27.89.96,1.13,1.26.37.45-2.99,3.89-2.68,4.39.57.96,1.02,2,1.31,3.11.1.39,4.78.74,4.85,1.14.1.63.12,1.31.12,1.97Z"
                    fill="none"
                    stroke={active ? activeColor : primaryColor}
                />
                <circle cx="61.86" cy="58.53" r="5.94" fill="none" stroke={active ? activeColor : primaryColor} />
            </g>
        </svg>
    );
};

const ShopSettings = ({ active, ...props }) => {
    return (
        <svg
            fill={active ? activeColor : primaryColor}
            strokeWidth="1.51px"
            strokeLinejoin="round"
            strokeLinecap="round"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 15 90.45 75"
            {...props}
        >
            <g>
                <polygon
                    points="37.88 35.85 37.88 62.34 15.86 52.07 15.86 27.57 37.88 35.85"
                    fill="none"
                    stroke={active ? activeColor : primaryColor}
                />
                <polyline
                    points="37.88 35.85 58.77 27.57 37.88 19.39 15.86 27.57"
                    fill="none"
                    stroke={active ? activeColor : primaryColor}
                />
                <line
                    x1="37.88"
                    y1="62.34"
                    x2="43.66"
                    y2="59.56"
                    fill="none"
                    stroke={active ? activeColor : primaryColor}
                />
                <line
                    x1="58.77"
                    y1="27.57"
                    x2="58.77"
                    y2="36.41"
                    fill="none"
                    stroke={active ? activeColor : primaryColor}
                />
                <line
                    x1="26.87"
                    y1="31.71"
                    x2="47.97"
                    y2="23.34"
                    fill="none"
                    stroke={active ? activeColor : primaryColor}
                />
            </g>
            <path
                d="M79.73,56.27c0,.59-.07,1.2-.14,1.77s-5.49,1.07-5.62,1.61c-.33,1.34-.85,2.61-1.52,3.77-.28.48,3.25,4.52,2.92,4.96-.4.53-.98,1.46-1.44,1.93-.37.38-1.09.89-1.5,1.22-.45.36-4.29-3.48-4.78-3.17-1.17.72-2.44,1.28-3.8,1.64-.53.14-.36,3.3-.82,4.62-.51,1.47-2.15,1.27-2.85,1.27-.5,0-2.21.14-2.7-1.02-.68-1.6-.13-4.66-.77-4.82-1.39-.35-2.7-.9-3.89-1.63-.3-.18-4.54,2.97-4.82,2.77-.72-.51-.98-.78-1.59-1.42-.44-.46-.79-1.19-1.16-1.7-.3-.41,2.91-4.22,2.66-4.66-.69-1.19-1.13-2.13-1.45-3.5-.12-.51-5.33-1-5.4-1.53-.07-.57-.26-1.82-.26-2.41,0-.49-.05-1.34,0-1.81.06-.56,5.4-.44,5.52-.98.33-1.51.91-2.93,1.68-4.21.3-.5-3.23-4.17-2.87-4.63.65-.82,2.33-2.42,3.14-3.09.55-.45,4.04,3.18,4.65,2.81,1.13-.67,2.37-1.2,3.67-1.54.43-.11,1.13-5.77,1.57-5.84.74-.12,1.24-.27,2.02-.27.68,0,1.68.15,2.34.25s.97,5.78,1.59,5.96c1.2.35,2.34.85,3.39,1.48.5.3,4.76-3.41,5.22-3.05s1.05.88,1.47,1.3c.32.32,1.05,1.12,1.33,1.47.43.53-3.5,4.56-3.15,5.15.67,1.12,1.2,2.35,1.54,3.65.12.46,5.61.87,5.69,1.34.12.73.14,1.54.14,2.31Z"
                fill="none"
                stroke={active ? activeColor : primaryColor}
                strokeWidth="2.79px"
            />
            <circle
                cx="60.16"
                cy="56.27"
                r="6.97"
                fill="none"
                stroke={active ? activeColor : primaryColor}
                strokeWidth="2.79px"
            />
        </svg>
    );
};

const Shelter = ({ active, ...props }) => {
    return (
        <svg
            fill={active ? activeColor : primaryColor}
            strokeWidth="1.51px"
            strokeLinejoin="round"
            strokeLinecap="round"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 15 90.45 90.45"
            {...props}
        >
            <path
                d="M69.2,54.93c-3.27,39.37-29.45,10.09-20.31-3.53,5.51-8.21,15.92,20.56-.4,28.35-17.32,8.27-20.24-22.15-12.7-29.36s6.58,23.81-3.82,22.83c-12.99-1.23-10.53-19.62-10.53-19.62l23.8-21.31,23.96,22.64Z"
                fill="none"
                stroke={active ? activeColor : primaryColor}
            />
            <path
                d="M43.28,76.73s-1.82-.79-1.82-2.19.54-1.03,1.57-.76,1.81-.13,2.3-.13.41,1.72-.4,2.35-1.65.73-1.65.73Z"
                fill={primaryColor}
                stroke={primaryColor}
                strokeMiterlimit="10"
                strokeWidth="1.54px"
            />
        </svg>
    );
};

const AdoptionRequests = ({ active, ...props }) => {
    return (
        <svg
            fill={active ? activeColor : primaryColor}
            strokeWidth="1.13px"
            strokeLinejoin="round"
            strokeLinecap="round"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 90.45 90.45"
            {...props}
        >
            <circle cx="44.04" cy="47.66" r="24.63" fill="none" stroke={active ? activeColor : primaryColor} />
            <path
                d="M49.84,36.42s-10.43-2.42-13.08,1.72-1.91,8.07-1.7,10.78-.8,6-1.75,6.8l-.96.8s.11-4.25-1.86-6.37-3.93-4.57-1.65-9.03,9.41-4.34,9.41-4.34"
                fill="none"
                stroke={active ? activeColor : primaryColor}
            />
            <path
                d="M36.54,46.12s.8-1.07,2.02-1.02,2.02.8,2.02.8"
                fill="none"
                stroke={active ? activeColor : primaryColor}
            />
            <path
                d="M46.89,46.12s.8-1.07,2.02-1.02,2.02.8,2.02.8"
                fill="none"
                stroke={active ? activeColor : primaryColor}
            />
            <path
                d="M50.68,38.29s2.47,2.55,2.18,8.04-.15,7.64.91,8.77,1.75,1.24,1.75,1.24c0,0-.58-2.4.62-4.22s4.36-3.94,4.11-7.68c-.33-5.02-7.28-7.06-7.28-7.06"
                fill="none"
                stroke={active ? activeColor : primaryColor}
            />
            <path
                d="M43.69,54.12s-1.97-2.15-1.97-3.6,3.8-1.09,4.15-.33c.69,1.53-2.18,3.93-2.18,3.93Z"
                fill="none"
                stroke={active ? activeColor : primaryColor}
            />
            <path
                d="M51.27,54.38s-1.44,3.31-4.38,2.55-3.19-2.8-3.19-2.8c0,0-.66,2.62-3.35,2.95s-3.8-2.66-3.8-2.66"
                fill="none"
                stroke={active ? activeColor : primaryColor}
            />
            <path
                d="M51.59,57.62s2.47,1.82,4.37,5.1c1.89,3.28,1.53,5.58,1.53,5.58"
                fill="none"
                stroke={active ? activeColor : primaryColor}
            />
            <path
                d="M36.09,57.62s-2.84,2.15-4.37,5.34-1.58,5.04-1.58,5.04"
                fill="none"
                stroke={active ? activeColor : primaryColor}
            />
            <path
                d="M41.54,56.73s-.1,3.4,2.15,3.4,2.6-3.38,2.6-3.38"
                fill="none"
                stroke={active ? activeColor : primaryColor}
            />
            <path
                d="M70.99,25.77c-.4,2.8-6.69,8.47-8.47,10.03-.09.08-.17.15-.23.2-.07-.06-.15-.12-.23-.2-1.78-1.56-8.07-7.23-8.47-10.03-.47-3.26,2.36-6.43,5.64-5.4,1.66.52,2.65,1.46,3.07,1.93.42-.47,1.42-1.41,3.07-1.93,3.28-1.03,6.1,2.14,5.63,5.4Z"
                fill={active ? activeColor : primaryColor}
                stroke={active ? activeColor : primaryColor}
                strokeMiterlimit="10"
                strokeWidth="1.54px"
            />
        </svg>
    );
};

const PackageHeart = ({ active, ...props }) => {
    return (
        <svg
            fill={active ? activeColor : primaryColor}
            strokeWidth="1.47px"
            strokeLinejoin="round"
            strokeLinecap="round"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 15 90.45 75"
            {...props}
        >
            <g>
                <polygon
                    points="35.82 46.74 35.82 72.23 14.62 62.36 14.62 38.77 35.82 46.74"
                    fill="none"
                    stroke={active ? activeColor : primaryColor}
                    strokeWidth="1.45px"
                />
                <polyline
                    points="35.82 46.74 55.93 38.77 35.82 30.9 14.62 38.77"
                    fill="none"
                    stroke={active ? activeColor : primaryColor}
                    strokeWidth="1.45px"
                />
                <line
                    x1="35.82"
                    y1="72.23"
                    x2="41.38"
                    y2="69.56"
                    fill="none"
                    stroke={active ? activeColor : primaryColor}
                    strokeWidth="1.45px"
                />
                <line
                    x1="55.93"
                    y1="38.77"
                    x2="55.93"
                    y2="47.28"
                    fill="none"
                    stroke={active ? activeColor : primaryColor}
                    strokeWidth="1.45px"
                />
                <line
                    x1="25.22"
                    y1="42.75"
                    x2="45.53"
                    y2="34.7"
                    fill="none"
                    stroke={active ? activeColor : primaryColor}
                    strokeWidth="1.45px"
                />
            </g>
            <g>
                <path
                    d="M68.26,60.18c-.65,4.55-10.88,13.78-13.78,16.3-.15.13-.27.24-.38.33-.11-.09-.24-.2-.38-.33-2.9-2.53-13.12-11.75-13.78-16.3-.76-5.3,3.84-10.46,9.16-8.78,2.69.85,4.31,2.37,4.99,3.14.68-.76,2.3-2.29,4.99-3.14,5.33-1.68,9.92,3.48,9.16,8.78Z"
                    fill={active ? activeColor : primaryColor}
                />
                <path
                    d="M53.74,76.81c-.11-.09-.24-.2-.38-.33-2.9-2.53-13.12-11.75-13.78-16.3-.76-5.3,3.84-10.46,9.16-8.78,2.69.85,4.31,2.37,4.99,3.14"
                    fill={active ? activeColor : primaryColor}
                    stroke={active ? activeColor : primaryColor}
                />
                <path
                    d="M69.59,55.42c.94,1.47.82,5.64-.98,8.53-1.8,2.89-5.04,6.4-5.04,6.4"
                    fill="none"
                    stroke="none"
                />
                <line
                    x1="55.85"
                    y1="77.82"
                    x2="61.99"
                    y2="71.94"
                    fill="none"
                    stroke="none"
                />
                <path
                    d="M54.53,51.58s4.29-3.35,8.58-2.12c4.29,1.23,5.23,4.12,5.23,4.12"
                    fill="none"
                    stroke="none"
                />
            </g>
        </svg>
    );
};

const Pets = ({ active, ...props }) => {
    return (
        <svg
            fill={active ? activeColor : primaryColor}
            strokeWidth="1.1px"
            strokeLinejoin="round"
            strokeLinecap="round"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 90.45 90.45"
            {...props}
        >
            <path
                d="M31.61,63.65c.54,2.28,2.19,4.2,4.45,4.85,7.15,2.08,20.87-2.22,24.65-5.17,4.72-3.68,5.06-9.92-2.92-19.15s-17.21-9.75-20.73-2.38c-3.52,7.37-5.75,12.99-5.97,16.63-.12,1.94.2,3.84.52,5.21Z"
                fill="none"
                stroke={active ? activeColor : primaryColor}
            />
            <path
                d="M59.87,40.13c.18,2.03,1.27,3.29,5.23,2.17s6.06-3.37,4.61-7.79-7.31-1.59-8.74,1.33-1.1,4.29-1.1,4.29Z"
                fill={active ? activeColor : primaryColor}
            />
            <path
                d="M50.98,27.49c-.7,3.16-.76,6.15,2.21,6.74s6.33-1.78,6.53-6.55-4.36-6.96-6.35-4.45-2.4,4.26-2.4,4.26Z"
                fill={active ? activeColor : primaryColor}
            />
            <path
                d="M40.35,33.79c-1.71-.51-2.2-4.99-1.39-9.12s4.44-4.25,6.19-.94,1.88,6.64-.22,8.6-4.57,1.47-4.57,1.47Z"
                fill={active ? activeColor : primaryColor}
            />
            <path
                d="M33.34,35.63c.39,2.53.48,6.28-2.39,6.99s-4.75-4.1-5.1-6.39.71-4.22,3.05-4.82c3.65-.94,4.43,4.22,4.43,4.22Z"
                fill={active ? activeColor : primaryColor}
            />
            <path
                d="M22.98,54.45c2,1.78,5.03,2.78,6.05,2.63s.6-3.95-.44-7.09-5.38-5.89-7.32-3.45c-2.98,3.75,1.71,7.91,1.71,7.91Z"
                fill={active ? activeColor : primaryColor}
            />
        </svg>
    );
};

const Transfers = ({ active, ...props }) => {
    return (
        <svg
            fill="none"
            strokeWidth="1.19px"
            strokeLinejoin="round"
            strokeLinecap="round"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 15 90.45 75"
            {...props}
        >
            <polyline
                points="25.59 40.74 58.58 40.74 51.47 32.03"
                stroke={active ? activeColor : primaryColor}
            />
            <polyline
                points="58.58 50.26 25.59 50.26 32.7 58.97"
                stroke={active ? activeColor : primaryColor}
            />
            <rect
                x="16.11"
                y="18.35"
                width="54.8"
                height="57.28"
                rx="4.64"
                ry="4.64"
                stroke={active ? activeColor : primaryColor}
            />
        </svg>
    );
};

const Orders = ({ active, ...props }) => {
    return (
        <svg
            fill="none"
            strokeLinejoin="round"
            strokeLinecap="round"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 90.45 90.45"
            {...props}
        >
            <polyline
                points="67.06 37.66 67.06 50.74 34.52 50.74 28.42 23.38 19.44 23.38"
                stroke={active ? activeColor : primaryColor}
                strokeWidth="1.26px"
            />
            <path
                d="M67.69,58.4H29.72c-2.27,0-4.11-1.84-4.11-4.11h0c0-2.27,1.84-4.11,4.11-4.11h1.09"
                stroke={active ? activeColor : primaryColor}
                strokeWidth="1.26px"
            />
            <circle
                cx="40.66"
                cy="58.4"
                r="3.39"
                fill={active ? activeColor : primaryColor}
                stroke={active ? activeColor : primaryColor}
                strokeMiterlimit="10"
                strokeWidth="1.12px"
            />
            <circle
                cx="61.81"
                cy="58.4"
                r="3.39"
                fill={active ? activeColor : primaryColor}
                stroke={active ? activeColor : primaryColor}
                strokeMiterlimit="10"
                strokeWidth="1.12px"
            />
            <circle
                cx="50.53"
                cy="34.83"
                r="7.89"
                stroke={active ? activeColor : primaryColor}
                strokeWidth="1.26px"
            />
            <polyline
                points="47.26 34.83 49.54 37.75 53.31 31.48"
                stroke={active ? activeColor : primaryColor}
                strokeWidth="0.85px"
            />
        </svg>
    );
};

const Calendar = ({ active, ...props }) => {
    return (
        <svg
            fill="none"
            strokeWidth="1.51px"
            strokeLinejoin="round"
            strokeLinecap="round"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 15 90.45 75"
            {...props}
        >
            <polyline
                points="43.47 70.53 22.83 70.53 22.83 46.73 61.49 46.73"
                stroke={active ? activeColor : primaryColor}
            />
            <path
                d="M29.68,35.39h24.45c4.06,0,7.36,3.3,7.36,7.36v3.98H22.83v-4.49c0-3.78,3.07-6.85,6.85-6.85Z"
                stroke={active ? activeColor : primaryColor}
            />
            <line x1="52.46" y1="31.02" x2="52.46" y2="41.06" stroke={active ? activeColor : primaryColor} />
            <line x1="31.89" y1="31.02" x2="31.89" y2="41.06" stroke={active ? activeColor : primaryColor} />
            <line x1="28.78" y1="54.39" x2="38.89" y2="54.39" stroke={active ? activeColor : primaryColor} />
            <line x1="28.78" y1="63.43" x2="38.89" y2="63.43" stroke={active ? activeColor : primaryColor} />
            <circle
                cx="57.01"
                cy="63.43"
                r="10.61"
                fill={active ? activeColor : primaryColor}
                stroke={active ? activeColor : primaryColor}
                strokeMiterlimit="10"
                strokeWidth="1.54px"
            />
            <polyline points="57.01 57.46 57.01 63.43 62.57 63.43" stroke={active ? primaryColor : activeColor} />
        </svg>
    );
};

const Document = ({ active, ...props }) => {
    const primaryColor = "#f4a6c8";
    const activeColor = "#eb4528";
    const strokeColor = active ? activeColor : primaryColor;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 90.45 90.45"
            {...props}
        >
            <polygon
                fill="none"
                stroke={strokeColor}
                strokeWidth="1.16"
                strokeLinecap="round"
                strokeLinejoin="round"
                points="32.65 19.22 32.65 73.26 73.09 73.26 73.09 36.97 55.22 19.54 32.65 19.22"
            />
            <polyline
                fill="none"
                stroke={strokeColor}
                strokeWidth="1.16"
                strokeLinecap="round"
                strokeLinejoin="round"
                points="55.22 19.54 55.22 37.08 73.09 37.08"
            />
            <line
                x1="42.89"
                y1="38.26"
                x2="47.6"
                y2="38.26"
                stroke={strokeColor}
                strokeWidth="2.79"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <line
                x1="43.39"
                y1="50.92"
                x2="62.35"
                y2="50.92"
                stroke={strokeColor}
                strokeWidth="2.79"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <line
                x1="42.89"
                y1="62.12"
                x2="62.35"
                y2="62.12"
                stroke={strokeColor}
                strokeWidth="2.79"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

const Platter = ({ active, ...props }) => {
    const fillColor = active ? activeColor : primaryColor;
    const strokeColor = active ? activeColor : primaryColor;

    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 60.24 50.7"
            {...props}
            fill="none" // Important to control fill via props/styles
        >
            <path className="cls-1" d="M36.18,17.74h3.84c.54,0,.77.69.33,1.01l-3.11,2.26c-.2.14-.28.4-.2.63l1.19,3.66c.17.52-.42.94-.86.63l-3.11-2.26c-.2-.14-.46-.14-.66,0l-3.11,2.26c-.44.32-1.03-.11-.86-.63l1.19-3.66c.07-.23,0-.48-.2-.63l-3.11-2.26c-.44-.32-.21-1.01.33-1.01h3.84c.24,0,.46-.16.53-.39l1.19-3.66c.17-.52.9-.52,1.06,0l1.19,3.66c.07.23.29.39.53.39Z" fill={fillColor} />
            <line className="cls-2" x1="42.88" y1="11.01" x2="39.52" y2="14.46" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth=".87" />
            <line className="cls-2" x1="33.93" y1="7.57" x2="33.93" y2="11.51" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth=".87" />
            <line className="cls-2" x1="24.89" y1="11.01" x2="28.48" y2="14.46" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth=".87" />
            <rect className="cls-2" x="21.01" y="28.12" width="26.09" height="3.02" rx="1.36" ry="1.36" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth=".87" />
            <line className="cls-2" x1="29.27" y1="36.97" x2="32.5" y2="31.14" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth=".87" />
            <polyline className="cls-2" points="20.38 35.71 21.68 35.71 24.07 31.14" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth=".87" />
            <path className="cls-2" d="M27.35,31.14v4.95l5.16,2.68s3.28-5.25,3.48-5.96c.34-1.17-.8-1.68-.8-1.68" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth=".87" />
            <line className="cls-2" x1="41.48" y1="31.14" x2="32.5" y2="38.78" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth=".87" />
            <path className="cls-2" d="M43.24,31.14c.55.38,1.72,1.89.25,3.31s-10.65,9.39-10.65,9.39H15.94v-9.98h4.28v9.98" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth=".87" />
        </svg>
    );
};

const Phone = ({ active, ...props }) => {
    const fillColor = active ? activeColor : primaryColor;
    const strokeColor = active ? activeColor : primaryColor;

    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 60.24 50.7"
            {...props}
            fill="none"
        >
            <circle className="cls-1" cx="35.96" cy="21.98" r="5.63" fill={fillColor} />
            <polyline className="cls-2" points="33.27 21.95 35.4 24.44 38.65 19.53" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth=".77" />
            <g>
                <line className="cls-2" x1="20.25" y1="21.95" x2="35.81" y2="37.51" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth=".77" />
                <path className="cls-2" d="M25.24,26.94s1.59-4.77,1.62-5.58-1.86-2.68-3.35-4.03-2.47-1.9-3.69-.95-4.33,3.66-4.33,6.54.95,3.76,5.69,8.6,9.82,10.02,11.41,10.63,4.17.64,6.06-.98,3.49-3.18,3.45-4.5-4-4.78-4.57-5.28-1.39-.3-2.64.14-3.77,1.3-3.77,1.3" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth=".77" />
            </g>
            <path className="cls-2" d="M41.88,12.87c2.71,1.63,4.53,4.6,4.53,7.99,0,.81-.1,1.6-.3,2.35" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth=".77" />
            <path className="cls-2" d="M35.05,11.78c.66-.15,1.34-.22,2.04-.22.99,0,1.93.15,2.83.44" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth=".77" />
        </svg>
    );
};

const Logout = ({ active, ...props }) => {
    const fillColor = active ? activeColor : primaryColor;
    return (
        <svg {...props}
             viewBox="0 0 24 24"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.2405 22.27H15.1105C10.6705 22.27 8.5305 20.52 8.1605 16.6C8.1205 16.19 8.4205 15.82 8.8405 15.78C9.2405 15.74 9.6205 16.05 9.6605 16.46C9.9505 19.6 11.4305 20.77 15.1205 20.77H15.2505C19.3205 20.77 20.7605 19.33 20.7605 15.26V8.73998C20.7605 4.66998 19.3205 3.22998 15.2505 3.22998H15.1205C11.4105 3.22998 9.9305 4.41998 9.6605 7.61998C9.6105 8.02998 9.2605 8.33998 8.8405 8.29998C8.4205 8.26998 8.1205 7.89998 8.1505 7.48998C8.4905 3.50998 10.6405 1.72998 15.1105 1.72998H15.2405C20.1505 1.72998 22.2505 3.82998 22.2505 8.73998V15.26C22.2505 20.17 20.1505 22.27 15.2405 22.27Z"
                fill={fillColor}/>
            <path
                d="M14.9991 12.75H3.61914C3.20914 12.75 2.86914 12.41 2.86914 12C2.86914 11.59 3.20914 11.25 3.61914 11.25H14.9991C15.4091 11.25 15.7491 11.59 15.7491 12C15.7491 12.41 15.4091 12.75 14.9991 12.75Z"
                fill={fillColor}/>
            <path
                d="M5.84945 16.1001C5.65945 16.1001 5.46945 16.0301 5.31945 15.8801L1.96945 12.5301C1.67945 12.2401 1.67945 11.7601 1.96945 11.4701L5.31945 8.12009C5.60945 7.83009 6.08945 7.83009 6.37945 8.12009C6.66945 8.41009 6.66945 8.89009 6.37945 9.18009L3.55945 12.0001L6.37945 14.8201C6.66945 15.1101 6.66945 15.5901 6.37945 15.8801C6.23945 16.0301 6.03945 16.1001 5.84945 16.1001Z"
                fill={fillColor}/>
        </svg>

    )
}

const News = ({ active, ...props }) => {
    const strokeColorRect = active ? activeColor : primaryColor;
    const strokeColorLines = active ? activeColor : primaryColor;
    const strokeWidthRect = 1.19;
    const strokeWidthLines = 2.79;

    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 90.45 90.45"
            {...props}
            fill="none"
        >
            <rect
                className="cls-2"
                x="17.29"
                y="25.84"
                width="50.17"
                height="50.17"
                rx="10.17"
                ry="10.17"
                stroke={strokeColorRect}
                strokeWidth={strokeWidthRect}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <g>
                <line
                    className="cls-1"
                    x1="32.81"
                    y1="43.48"
                    x2="51.95"
                    y2="43.48"
                    stroke={strokeColorLines}
                    strokeWidth={strokeWidthLines}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <line
                    className="cls-1"
                    x1="32.81"
                    y1="58.37"
                    x2="51.95"
                    y2="58.37"
                    stroke={strokeColorLines}
                    strokeWidth={strokeWidthLines}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

const Sellers = ({ active, ...props }) => {
    const strokeColorCls1 = active ? activeColor : primaryColor;
    const fillColorCls2 = active ? activeColor : primaryColor;
    const strokeColorCls2 = active ? activeColor : primaryColor;
    const strokeWidthCls1 = 1.19;
    const strokeWidthCls2 = 1.05;

    const viewBoxValue = '0 0 90.45 90.45';

    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBoxValue}
            {...props}
            fill="none"
        >
            <path
                className="cls-1"
                d="M59.88,68.22h-29c-4.2,0-7.7-3.23-8.03-7.42l-1.86-23.55c-.37-4.68,3.33-8.69,8.03-8.69h32.4c4.65,0,8.34,3.93,8.04,8.58l-1.54,23.55c-.28,4.23-3.79,7.53-8.04,7.53Z"
                stroke={strokeColorCls1}
                strokeWidth={strokeWidthCls1}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <polyline
                className="cls-1"
                points="21.19 39.68 45.22 50.39 69.48 41.14"
                stroke={strokeColorCls1}
                strokeWidth={strokeWidthCls1}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                className="cls-2"
                x="39.21"
                y="42.44"
                width="11.92"
                height="11.92"
                rx="5.96"
                ry="5.96"
                fill={fillColorCls2}
                stroke={strokeColorCls2}
                strokeMiterlimit="10"
                strokeWidth={strokeWidthCls2}
            />
            <path
                className="cls-1"
                d="M35.65,28.56h0c0-3.49,2.83-6.33,6.33-6.33h6.49c3.49,0,6.33,2.83,6.33,6.33h0"
                stroke={strokeColorCls1}
                strokeWidth={strokeWidthCls1}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};


const Banner = ({active, ...props}) => {
    const strokeColorCls1 = active ? activeColor : primaryColor;
    const fillColorCls2 = active ? activeColor : primaryColor;
    const strokeColorCls2 = active ? activeColor : primaryColor;
    const strokeWidthCls1 = 1.19;
    const strokeWidthCls2 = 1.05;

    const viewBoxValue = '0 0 90.45 90.45';

    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBoxValue}
            {...props}
            fill="none"
        >
            <path
                className="cls-1"
                d="M62.65,25.84v10.75c0,.52-.42.94-.94.94H24.68c-.52,0-.94.42-.94.94v10.75c0,.52-.42.94-.94.94h-3.18c-.52,0-.94-.42-.94-.94v-23.39c0-.52.42-.94.94-.94h42.1c.52,0,.94.42.94.94Z"
                stroke={strokeColorCls1}
                strokeWidth={strokeWidthCls1}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                className="cls-1"
                d="M67.72,38.48v7.19c0,.52-.42.94-.94.94H31.73c-.52,0-.94.42-.94.94v14.31c0,.52-.42.94-.94.94h-5.16c-.52,0-.94-.42-.94-.94v-23.39c0-.52.42-.94.94-.94h42.1c.52,0,.94.42.94.94Z"
                stroke={strokeColorCls1}
                strokeWidth={strokeWidthCls1}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <rect
                className="cls-2"
                x="30.78"
                y="46.62"
                width="43.99"
                height="25.28"
                rx=".94"
                ry=".94"
                fill={fillColorCls2}
                stroke={strokeColorCls2}
                strokeMiterlimit="10"
                strokeWidth={strokeWidthCls2}
            />
        </svg>
    );
};

const Reports = ({active, ...props}) => {
    const strokeColorCls2 = active ? activeColor : primaryColor;
    const strokeColorCls1 = active ? activeColor : primaryColor;
    const strokeWidthCls2 = 1.27;
    const strokeWidthCls1 = 3.53;

    const viewBoxValue = '0 0 90.45 90.45';

    return (
        <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBoxValue}
            {...props}
            fill="none"
        >
            <path
                className="cls-2"
                d="M20.03,62.86c-3.44-4.47-5.48-10.07-5.48-16.14,0-14.64,11.87-26.5,26.5-26.5,7.49,0,14.25,3.11,19.07,8.1"
                stroke={strokeColorCls2}
                strokeWidth={strokeWidthCls2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                className="cls-2"
                d="M62.37,30.97c3.26,4.4,5.19,9.85,5.19,15.75,0,14.64-11.87,26.5-26.5,26.5-7.55,0-14.36-3.16-19.19-8.22"
                stroke={strokeColorCls2}
                strokeWidth={strokeWidthCls2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                className="cls-2"
                d="M51.36,61.63c-2.92,2.03-6.48,3.22-10.31,3.22-1.25,0-2.47-.13-3.65-.37"
                stroke={strokeColorCls2}
                strokeWidth={strokeWidthCls2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                className="cls-2"
                d="M44.92,29.1c8.13,1.77,14.22,9.01,14.22,17.67,0,5.41-2.37,10.26-6.14,13.58"
                stroke={strokeColorCls2}
                strokeWidth={strokeWidthCls2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                className="cls-2"
                d="M33.57,30.3c2.28-1.04,4.81-1.61,7.48-1.61.74,0,1.47.04,2.19.13"
                stroke={strokeColorCls2}
                strokeWidth={strokeWidthCls2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                className="cls-2"
                d="M35.34,63.93c-7.19-2.39-12.37-9.17-12.37-17.17,0-6.65,3.58-12.45,8.93-15.6"
                stroke={strokeColorCls2}
                strokeWidth={strokeWidthCls2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                className="cls-2"
                d="M33.19,58.14c-.79-.55-1.53-1.19-2.19-1.9"
                stroke={strokeColorCls2}
                strokeWidth={strokeWidthCls2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                className="cls-2"
                d="M30.08,55.16c-1.75-2.31-2.79-5.18-2.79-8.3,0-7.6,6.16-13.76,13.76-13.76s13.76,6.16,13.76,13.76-6.16,13.76-13.76,13.76c-2.3,0-4.47-.56-6.37-1.56"
                stroke={strokeColorCls2}
                strokeWidth={strokeWidthCls2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <polyline
                className="cls-2"
                points="21.55 69.83 21.55 64.86 26.53 64.86"
                stroke={strokeColorCls2}
                strokeWidth={strokeWidthCls2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <polyline
                className="cls-2"
                points="60.12 23.41 60.12 28.38 55.15 28.38"
                stroke={strokeColorCls2}
                strokeWidth={strokeWidthCls2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <polyline
                className="cls-1"
                points="33.83 47.57 37.61 51.5 48.84 40.43"
                stroke={strokeColorCls1}
                strokeWidth={strokeWidthCls1}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

const titleToIconMap = {
    "Dashboard": UnEvenBoxes,

    "Shop": Orders,
    "Products": PackageHeart,
    "Orders": Orders,
    "Discounts": Platter,

    "Bookings": Phone,
    "Services": PackageHeart,
    "Appointments": Calendar,
    "Calendar": Calendar,
    "Employees": AccountManager,

    "Shelter": Shelter,
    "Pets": Pets,
    "Adoption Orders": AdoptionRequests,
    "Transfers": Transfers,

    "Documents": Document,
    "News": News,
    "Sellers": Sellers,
    "Home Banners": Banner,
    "Product Templates": PackageHeart,
    "Product Categories": PackageHeart,
    "Service Categories": ShopSettings,
    "Reports": Reports,
    "App Settings": SettingsCog,


    "Settings": SettingsCog,
    "Account Manager": AccountManager,
    "Profile Settings": ProfileSettings,
    "Shop Settings": ShopSettings,

    "Support": SupportPhone,
}

const DashboardIcon = ({title, active}) => {
    const IconComponent = titleToIconMap[title] || Grid;
    return <IconComponent active={active} width={40} height={40}/>;
}


export {
    DashboardIcon,
    Document as DocumentIcon,
    Logout as LogoutIcon
}