// @ts-ignore
import React, {SetStateAction, useEffect, useState} from "react";
import {Link} from 'react-router-dom'
// @ts-ignore
import ProductFiltersModal from "./ProductFiltersPopup.tsx";
// @ts-ignore
import ProductModal from "./ProductModal.tsx";
import ImportFilePopup from "../../common/ImportFilePopup";
import SearchBar from "../../common/SearchBar";
import {Product, ProductListingApiRequest, ProductListingApiResponse} from "./types";

import {exportIcon, importIcon, printIcon, viewIcon, viewIconWhite,
    editIcon,
    editIconWhite,
    deleteIcon,
    deleteIconWhite
} from "../../../../resources/themes/dashboard-v1/icons/icons";

import { notify_promise } from "../../../../services/utils/toasts";

import {
    delete_shop_products,
    fetch_shop_products,
    export_shop_products,
    IMPORT_API,
} from "../../../../services/shops/products";
// @ts-ignore
import DynamicDataTable from "../../common/DynamicDataTable.tsx";
import {useSearchParams} from "react-router-dom";
// @ts-ignore
import {clearInputsInContainerID} from "../../../../utils/html.ts";

const defaultRequestOptions = {
    page: 0,
    perPage: 20
}

const areFiltersApplied = (searchParams: URLSearchParams) => {
    return searchParams.size > 2
}

const ProductListingPage = () => {
    const [pageReadyStatus, setPageReadyStatus] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [response, setResponse] = useState<ProductListingApiResponse>(undefined);
    const [selectedProduct, setSelectedProduct] = React.useState<Product>(undefined);

    // Set Request Options & Fetch results based on URL params whenever they change or are loaded initially.
    useEffect(() => {
        if (!searchParams.has("page")) {
            searchParams.set("page", "0")
        }
        if (!searchParams.has("perPage")) {
            searchParams.set("perPage", "10")
        }

        // @ts-ignore
        const params: ProductListingApiRequest = searchParams.size === 0 ? defaultRequestOptions : Object.fromEntries([...searchParams.entries()]);
        fetch_shop_products(params).then((apiResponse: ProductListingApiResponse) => {
            apiResponse.fields.push({key: "actions", label: "Actions", type: "actions"})
            setResponse(apiResponse);
            setPageReadyStatus(true);
        })
    }, [searchParams]);

    if (!pageReadyStatus) {
        return <div className="page-content py-3 px-4 bg-lightgray">
            <div    className={"d-flex flex-column justify-content-center align-middle text-center"}
                    style={{minHeight: 400}}>
                <h5>Loading.. </h5>
            </div>
        </div>
    }

    return <div className="page-content py-3 px-4 bg-lightgray">
        <ProductModal id={"newProductModal"} product={selectedProduct} available_discounts={response.available_discounts}/>
        <PageHeader response={response} searchParams={searchParams} setSearchParams={setSearchParams}/>
        <DynamicDataTable
            fields={response.fields}
            data={response.data}
            pagination={response}
            Actions={(props: ActionsCellProps) => {
                return <Actions row={props.row}
                                setSelectedProduct={setSelectedProduct}
                                setResponse={setResponse}
                />
            }}
        />
    </div>
}

export default ProductListingPage;

const exportFunction = () => {
    notify_promise(
        new Promise((resolve, reject) => {
            export_shop_products()
                .then((res) => {
                    resolve(res);
                })
                .catch(reject);
        }),
        "Export in progress! Watch your inbox for the download link soon",
        "📧"
    );
};

interface PageHeaderProps {
    response: ProductListingApiResponse
    searchParams: URLSearchParams
    setSearchParams: React.Dispatch<SetStateAction<string>>
}

const PageHeader: React.FC<PageHeaderProps> = ({response, searchParams, setSearchParams}) => {
    return <div className={"d-flex flex-column gap-2 no-print"}>
        <div className={"mb-2 d-flex justify-content-between"}>
            <div>
                <h3>Products</h3>
                <p>Browse, filter and manage your shop's products</p>
            </div>
            <div className={"d-flex align-middle justify-content-center h-100"}>
                {response !== undefined && response.filter_fields !== undefined && response.filter_fields.length > 0 && (
                    <ProductFiltersModal
                        dynamicFields={response.filter_fields}
                        onFilterSubmit={(filters: Record<string, any>) => {
                            setSearchParams((prevParams: URLSearchParams) => {
                                const filtersToInclude = {}
                                Object.entries(filters).forEach(([key, value]) => {
                                    if (value) {
                                        filtersToInclude[key] = value
                                    }
                                })
                                const newParams = new URLSearchParams(filtersToInclude);
                                newParams.set("page", "1")
                                newParams.set("perPage", "10")
                                if (prevParams.has("search")) {
                                    newParams.set("search", prevParams.get("search"));
                                }
                                return newParams
                            })
                        }}/>
                )}
                <div className={"px-2"}>
                    <button className={"btn btn-secondary"}
                            disabled={!areFiltersApplied(searchParams)}
                            onClick={() => {
                                // for some reason changing the input values across to the model is not cutting it.
                                // so I had to clear inputs here.
                                clearInputsInContainerID("productFilters")
                                clearInputsInContainerID("legacySearchBar")

                                const newParams = new URLSearchParams();
                                newParams.set("page", "1")
                                newParams.set("perPage", "10")

                                setSearchParams(newParams)
                            }}>Clear
                    </button>
                </div>
            </div>
        </div>

        <div className={"d-flex desktop-flex-row justify-content-between page-control-container gap-2 mb-3"}>
            <div className={"d-flex desktop-flex-row gap-2"}>
                <div>
                    <Link to={"/admin/products/add"} className={"btn btn-primary p-3"}>
                        + Add New Product
                    </Link>
                </div>
                {response.settings.allow_imports && (
                    <div>
                        <ImportFilePopup upload_endpoint={IMPORT_API}/>
                        <ImportButton/>
                    </div>
                )}
            </div>
            <div className={"d-flex desktop-flex-row gap-2"}>
                <LegacySearchBar/>
                <PrintButton/>
                <ExportButton/>
            </div>
        </div>

    </div>
}

const LegacySearchBar = () => {
    const [legacyObj, setLegacyObj] = useState({search: ""});
    const [searchParams, setSearchParams] = useSearchParams();

    // this is done coz the legacy search bar overwrites all query params
    useEffect(() => {
        setSearchParams(prev => {
            if (legacyObj.search.length === 0) {
                prev.delete("search")
            } else {
                prev.set("search", legacyObj.search)
            }
            prev.set("page", "1")
            prev.set("perPage", "10")
            return prev
        })
    }, [legacyObj]);

    return <div id={"legacySearchBar"}>
        <SearchBar type={"list"} resultType={"product"} setSearch={setLegacyObj}/>
    </div>
}

const ImportButton = () => {
    return <div className="d-flex flex-column align-items-center">
        <button
            type="button"
            className="btn btn-primary btn-light-primary p-3"
            onClick={(e) => {
                e.preventDefault()
                document.getElementById("importFilePopup").style.display = "flex";
            }}
        >
            <span
                className={"btn-icon btn-icon-left"}>
                {importIcon}
            </span>
            Import From File
        </button>
        <div className="sample">
            <a
                href={`https://public.pawpaws.ae/public/downloadable-content/import-sample-products.csv`}
                download={`import-sample-products.csv`}
            >
                Download Sample File
            </a>
        </div>
    </div>
}

const ExportButton = () => {
    return <div>
        <button className={"btn btn-primary p-3"} onClick={exportFunction}>
            Export
            <span
                className={"btn-icon btn-icon-right"}
            >
            {exportIcon}
        </span>
        </button>
    </div>
}

const PrintButton = () => {
    return <div>
        <button
            className="btn btn-primary btn-light-primary p-3"
            onClick={() => window.print()}
        >
            Print
            <span className="btn-icon btn-icon-right">
            {printIcon}
        </span>
        </button>
    </div>
}

interface ActionsCellProps {
    row: Product
    setSelectedProduct: (product: Product) => void
    setResponse: React.Dispatch<React.SetStateAction<ProductListingApiResponse>>
}

const Actions: React.FC<ActionsCellProps> = ({row, setSelectedProduct, setResponse}) => {
    return <div className={"d-flex flex-row justify-content-center align-items-center actions-td"}>
        <button type="button"
                className={"btn btn-icon d-flex flex-row justify-content-center align-items-center m-1 btn-icon-light-info"}
                onClick={(e) => {
                    e.preventDefault();
                    setSelectedProduct(row)
                    const modalElement = document.getElementById('newProductModal');
                    const modal = bootstrap.Modal.getOrCreateInstance(modalElement);
                    modal.show();
                }}>
            <span className="hide-hover-item svg">
                {viewIcon}
            </span>
            <span className="hover-item svg">
                {viewIconWhite}
            </span>
        </button>
        <Link to={`/admin/products/edit/${row.id}`}
              className={"btn btn-icon d-flex flex-row justify-content-center align-items-center m-1 btn-icon-light-warning"}>
            <span className="hide-hover-item svg">
                {editIcon}
            </span>
            <span className="hover-item svg">
                {editIconWhite}
            </span>
        </Link>
        <button
            className={"btn btn-icon d-flex flex-row justify-content-center align-items-center m-1 btn-icon-light-danger"}
            onClick={() => {
                deleteProduct(row.id, setResponse)
            }}
        >
            <span className="hide-hover-item svg">
                {deleteIcon}
            </span>
            <span className="hover-item svg">
                {deleteIconWhite}
            </span>
        </button>
    </div>
}

const deleteProduct = (id: number, setResponse: React.Dispatch<React.SetStateAction<ProductListingApiResponse>>) => {
    notify_promise(
        new Promise((resolve, reject) => {
            delete_shop_products([id])
                .then((res) => {
                    setResponse((prevResponse: ProductListingApiResponse) => ({
                        ...prevResponse, // Spread previous state
                        data: prevResponse.data.filter((p: Product) => p.id !== id),
                    }));
                    resolve(res);
                })
                .catch(reject);
        })
    );
}