// @ts-ignore
import React, {useEffect} from 'react';

interface SignupFailScreenProps {
    className: string;
    errors: string[];
    updateTimeLine: (screenNumber: number) => void;
}

const SignupFailScreen: React.FC<SignupFailScreenProps> = ({
    className,
    errors,
    updateTimeLine,
}) => {

    return (
        <div className="center-content">
            <div className={`${className} text-center px-md-5 px-2`}>
                <div className='px-lg-5 px-0'>
                    <h2 className="mb-1 fw-bold text-center">We may have an issue</h2>
                    {errors.map((errorMessage) => {
                        return (
                            <p className="text-danger p-2 text-center">{errorMessage}</p>
                        )
                    })}


                    {
                        errors.length === 0 ? (
                            <p className="form-help text-center">An error occured please go back and update your info</p>
                        ) : <></>
                    }


                    <button className="btn btn-warning py-2 mt-4 fw-bold w-100" onClick={(e) => {
                            e.preventDefault()
                            updateTimeLine(3)
                        }}>
                            Go Back
                        </button>
                </div>
            </div>
        </div>
    );
}

export default SignupFailScreen;
