import React from "react";

export const dropdownCaret = <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <g clipPath="url(#clip0_1042_22552)">
        <path
            d="M8.00065 15.1663C4.04732 15.1663 0.833984 11.953 0.833984 7.99967C0.833984 4.04634 4.04732 0.833008 8.00065 0.833008C11.954 0.833008 15.1673 4.04634 15.1673 7.99967C15.1673 11.953 11.954 15.1663 8.00065 15.1663ZM8.00065 1.83301C4.60065 1.83301 1.83398 4.59967 1.83398 7.99967C1.83398 11.3997 4.60065 14.1663 8.00065 14.1663C11.4007 14.1663 14.1673 11.3997 14.1673 7.99967C14.1673 4.59967 11.4007 1.83301 8.00065 1.83301Z"
            fill="white"
        />
        <path
            d="M8.00013 10.0069C7.87346 10.0069 7.7468 9.96026 7.6468 9.86026L5.29344 7.50693C5.1001 7.3136 5.1001 6.9936 5.29344 6.80026C5.48677 6.60694 5.80677 6.60694 6.0001 6.80026L8.00013 8.80026L10.0001 6.80026C10.1935 6.60694 10.5135 6.60694 10.7068 6.80026C10.9001 6.9936 10.9001 7.3136 10.7068 7.50693L8.35346 9.86026C8.25346 9.96026 8.1268 10.0069 8.00013 10.0069Z"
            fill="white"
        />
    </g>
    <defs>
        <clipPath id="clip0_1042_22552">
            <rect width="16" height="16" fill="white"/>
        </clipPath>
    </defs>
</svg>
export const circleXIcon = <svg
    width="25"
    height="25"
    viewBox="4 4 20 20"
    fill="#EBEBEB"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M10.6982 18.1766C10.4766 18.1766 10.2549 18.0949 10.0799 17.9199C9.74159 17.5816 9.74159 17.0216 10.0799 16.6832L16.6833 10.0799C17.0216 9.74159 17.5816 9.74159 17.9199 10.0799C18.2583 10.4183 18.2583 10.9783 17.9199 11.3166L11.3166 17.9199C11.1532 18.0949 10.9199 18.1766 10.6982 18.1766Z"
        fill="#434343"
    />
    <path
        d="M17.3016 18.1766C17.0799 18.1766 16.8583 18.0949 16.6833 17.9199L10.0799 11.3166C9.74159 10.9783 9.74159 10.4183 10.0799 10.0799C10.4182 9.74159 10.9782 9.74159 11.3166 10.0799L17.9199 16.6832C18.2583 17.0216 18.2583 17.5816 17.9199 17.9199C17.7449 18.0949 17.5233 18.1766 17.3016 18.1766Z"
        fill="#434343"
    />
</svg>
export const chatMessageIcon = <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M14.166 1.66699H5.83268C3.53268 1.66699 1.66602 3.52533 1.66602 5.81699V10.8003V11.6337C1.66602 13.9253 3.53268 15.7837 5.83268 15.7837H7.08268C7.30768 15.7837 7.60768 15.9337 7.74935 16.117L8.99935 17.7753C9.54935 18.5087 10.4493 18.5087 10.9993 17.7753L12.2493 16.117C12.4077 15.9087 12.6577 15.7837 12.916 15.7837H14.166C16.466 15.7837 18.3327 13.9253 18.3327 11.6337V5.81699C18.3327 3.52533 16.466 1.66699 14.166 1.66699ZM6.66602 10.0003C6.19935 10.0003 5.83268 9.62533 5.83268 9.16699C5.83268 8.70866 6.20768 8.33366 6.66602 8.33366C7.12435 8.33366 7.49935 8.70866 7.49935 9.16699C7.49935 9.62533 7.13268 10.0003 6.66602 10.0003ZM9.99935 10.0003C9.53268 10.0003 9.16602 9.62533 9.16602 9.16699C9.16602 8.70866 9.54102 8.33366 9.99935 8.33366C10.4577 8.33366 10.8327 8.70866 10.8327 9.16699C10.8327 9.62533 10.466 10.0003 9.99935 10.0003ZM13.3327 10.0003C12.866 10.0003 12.4993 9.62533 12.4993 9.16699C12.4993 8.70866 12.8743 8.33366 13.3327 8.33366C13.791 8.33366 14.166 8.70866 14.166 9.16699C14.166 9.62533 13.7993 10.0003 13.3327 10.0003Z"
        fill="#707070"
    />
</svg>
export const phoneIcon = <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M9.20768 12.4587L7.66602 14.0003C7.34102 14.3253 6.82435 14.3253 6.49102 14.0087C6.39935 13.917 6.30768 13.8337 6.21602 13.742C5.35768 12.8753 4.58268 11.967 3.89102 11.017C3.20768 10.067 2.65768 9.11699 2.25768 8.17533C1.86602 7.22533 1.66602 6.31699 1.66602 5.45033C1.66602 4.88366 1.76602 4.34199 1.96602 3.84199C2.16602 3.33366 2.48268 2.86699 2.92435 2.45033C3.45768 1.92533 4.04102 1.66699 4.65768 1.66699C4.89102 1.66699 5.12435 1.71699 5.33268 1.81699C5.54935 1.91699 5.74102 2.06699 5.89102 2.28366L7.82435 5.00866C7.97435 5.21699 8.08268 5.40866 8.15768 5.59199C8.23268 5.76699 8.27435 5.94199 8.27435 6.10033C8.27435 6.30033 8.21602 6.50033 8.09935 6.69199C7.99102 6.88366 7.83268 7.08366 7.63268 7.28366L6.99935 7.94199C6.90768 8.03366 6.86602 8.14199 6.86602 8.27533C6.86602 8.34199 6.87435 8.40033 6.89102 8.46699C6.91602 8.53366 6.94102 8.58366 6.95768 8.63366C7.10768 8.90866 7.36602 9.26699 7.73268 9.70033C8.10768 10.1337 8.50768 10.5753 8.94102 11.017C9.02435 11.1003 9.11602 11.1837 9.19935 11.267C9.53268 11.592 9.54102 12.1253 9.20768 12.4587Z"
        fill="#707070"
    />
    <path
        d="M18.3083 15.2742C18.3083 15.5076 18.2667 15.7492 18.1833 15.9826C18.1583 16.0492 18.1333 16.1159 18.1 16.1826C17.9583 16.4826 17.775 16.7659 17.5333 17.0326C17.125 17.4826 16.675 17.8076 16.1667 18.0159C16.1583 18.0159 16.15 18.0242 16.1417 18.0242C15.65 18.2242 15.1167 18.3326 14.5417 18.3326C13.6917 18.3326 12.7833 18.1326 11.825 17.7242C10.8667 17.3159 9.90832 16.7659 8.95833 16.0742C8.63333 15.8326 8.30833 15.5909 8 15.3326L10.725 12.6076C10.9583 12.7826 11.1667 12.9159 11.3417 13.0076C11.3833 13.0242 11.4333 13.0492 11.4917 13.0742C11.5583 13.0992 11.625 13.1076 11.7 13.1076C11.8417 13.1076 11.95 13.0576 12.0417 12.9659L12.675 12.3409C12.8833 12.1326 13.0833 11.9742 13.275 11.8742C13.4667 11.7576 13.6583 11.6992 13.8667 11.6992C14.025 11.6992 14.1917 11.7326 14.375 11.8076C14.5583 11.8826 14.75 11.9909 14.9583 12.1326L17.7167 14.0909C17.9333 14.2409 18.0833 14.4159 18.175 14.6242C18.2583 14.8326 18.3083 15.0409 18.3083 15.2742Z"
        fill="#707070"
    />
</svg>
export const locationIcon = <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M17.1849 7.04134C16.3099 3.19134 12.9516 1.45801 10.0016 1.45801C10.0016 1.45801 10.0016 1.45801 9.99324 1.45801C7.05154 1.45801 3.68488 3.18301 2.80988 7.03301C1.83488 11.333 4.46821 14.9747 6.85154 17.2663C7.73488 18.1163 8.86824 18.5413 10.0016 18.5413C11.1349 18.5413 12.2682 18.1163 13.1432 17.2663C15.5266 14.9747 18.1599 11.3413 17.1849 7.04134ZM10.0016 11.2163C8.55157 11.2163 7.37654 10.0413 7.37654 8.59134C7.37654 7.14134 8.55157 5.96634 10.0016 5.96634C11.4516 5.96634 12.6266 7.14134 12.6266 8.59134C12.6266 10.0413 11.4516 11.2163 10.0016 11.2163Z"
        fill="#707070"
    />
</svg>


export const homeIcon = <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 96"
    width="20"
    height="20"
>
    <path
        fill="#707070"
        d="m88.894 30.21-40-20a2.002 2.002 0 0 0-1.789 0l-40 20A2.005 2.005 0 0 0 6 32v8a2.009 2.009 0 0 0 .95 1.703 2.035 2.035 0 0 0 1.945.086L48 22.234 87.106 41.79A1.999 1.999 0 0 0 90 40v-8a2.005 2.005 0 0 0-1.106-1.79Z"
        className="color646464 svgShape"
    ></path>
    <path
        fill="#707070"
        d="M12 44.709V84a1.999 1.999 0 0 0 2 2h68a1.999 1.999 0 0 0 2-2V44.709L48 26.707Zm44.43 1.764C59.863 47.777 62 51.44 62 56.035c0 8.14-6.832 14.93-13.18 17.79a2.002 2.002 0 0 1-1.64 0C40.831 70.964 34 64.174 34 56.034c0-4.594 2.137-8.258 5.57-9.562 2.742-1.04 5.777-.344 8.43 1.867 2.652-2.211 5.688-2.906 8.43-1.867Z"
        className="color646464 svgShape"
    ></path>
    <path
        fill="#707070"
        d="M46.504 52.45A6.184 6.184 0 0 0 42.156 50a3.284 3.284 0 0 0-1.164.21C39.176 50.903 38 53.188 38 56.036c0 6.383 5.703 11.516 10 13.742 4.297-2.226 10-7.359 10-13.742 0-2.848-1.176-5.133-2.992-5.824-1.68-.633-3.68.176-5.512 2.238a2.072 2.072 0 0 1-2.992 0Z"
        className="color646464 svgShape"
    ></path>
</svg>

export const exportIcon = <svg width="16" height="17" viewBox="0 0 16 17" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
    <path
        d="M10.1737 15.3332H5.82702C2.55369 15.3332 1.15369 13.9332 1.15369 10.6598V10.5732C1.15369 7.61317 2.32035 6.18652 4.93369 5.93985C5.20035 5.91985 5.45369 6.11985 5.48035 6.39319C5.50702 6.66652 5.30702 6.91319 5.02702 6.93985C2.93369 7.13319 2.15369 8.11983 2.15369 10.5798V10.6665C2.15369 13.3798 3.11369 14.3398 5.82702 14.3398H10.1737C12.887 14.3398 13.847 13.3798 13.847 10.6665V10.5798C13.847 8.1065 13.0537 7.11985 10.9204 6.93985C10.647 6.91319 10.4404 6.67319 10.467 6.39985C10.4937 6.12652 10.727 5.91985 11.007 5.94652C13.6604 6.17319 14.847 7.6065 14.847 10.5865V10.6732C14.847 13.9332 13.447 15.3332 10.1737 15.3332Z"
        fill="white"/>
    <path
        d="M8 10.9997C7.72667 10.9997 7.5 10.7731 7.5 10.4997V2.91309C7.5 2.63975 7.72667 2.41309 8 2.41309C8.27333 2.41309 8.5 2.63975 8.5 2.91309V10.4997C8.5 10.7731 8.27333 10.9997 8 10.9997Z"
        fill="white"/>
    <path
        d="M10.2332 4.89964C10.1065 4.89964 9.97983 4.85297 9.87983 4.75297L7.99983 2.87297L6.11986 4.75297C5.92652 4.9463 5.60652 4.9463 5.41319 4.75297C5.21986 4.55964 5.21986 4.23964 5.41319 4.0463L7.6465 1.81297C7.83983 1.61964 8.15983 1.61964 8.35316 1.81297L10.5865 4.0463C10.7798 4.23964 10.7798 4.55964 10.5865 4.75297C10.4932 4.85297 10.3598 4.89964 10.2332 4.89964Z"
        fill="white"/>
</svg>


export const importIcon = <svg width="16" height="17" viewBox="0 0 16 17" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
    <path
        d="M9.20012 7.79993C9.07345 7.79993 8.94678 7.75327 8.84678 7.65327C8.65345 7.45994 8.65345 7.13996 8.84678 6.94663L14.3134 1.47996C14.5068 1.28663 14.8268 1.28663 15.0201 1.47996C15.2134 1.67329 15.2134 1.99329 15.0201 2.18663L9.55345 7.65327C9.46012 7.7466 9.33345 7.79993 9.20012 7.79993Z"
        fill="#DC5237"/>
    <path
        d="M11.8867 8.33327H8.66669C8.39335 8.33327 8.16669 8.1066 8.16669 7.83327V4.61328C8.16669 4.33995 8.39335 4.11328 8.66669 4.11328C8.94002 4.11328 9.16669 4.33995 9.16669 4.61328V7.33327H11.8867C12.16 7.33327 12.3867 7.55993 12.3867 7.83327C12.3867 8.1066 12.16 8.33327 11.8867 8.33327Z"
        fill="#DC5237"/>
    <path
        d="M9.99998 15.6663H5.99998C2.37998 15.6663 0.833313 14.1197 0.833313 10.4997V6.49967C0.833313 2.87967 2.37998 1.33301 5.99998 1.33301H7.33331C7.60665 1.33301 7.83331 1.55967 7.83331 1.83301C7.83331 2.10634 7.60665 2.33301 7.33331 2.33301H5.99998C2.92665 2.33301 1.83331 3.42634 1.83331 6.49967V10.4997C1.83331 13.573 2.92665 14.6663 5.99998 14.6663H9.99998C13.0733 14.6663 14.1666 13.573 14.1666 10.4997V9.16634C14.1666 8.89301 14.3933 8.66634 14.6666 8.66634C14.94 8.66634 15.1666 8.89301 15.1666 9.16634V10.4997C15.1666 14.1197 13.62 15.6663 9.99998 15.6663Z"
        fill="#DC5237"/>
</svg>


export const printIcon = <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M11.1667 5.66634H4.83337C4.56004 5.66634 4.33337 5.43967 4.33337 5.16634V3.83301C4.33337 2.12634 5.12671 1.33301 6.83337 1.33301H9.16671C10.8734 1.33301 11.6667 2.12634 11.6667 3.83301V5.16634C11.6667 5.43967 11.44 5.66634 11.1667 5.66634ZM5.33337 4.66634H10.6667V3.83301C10.6667 2.69967 10.3 2.33301 9.16671 2.33301H6.83337C5.70004 2.33301 5.33337 2.69967 5.33337 3.83301V4.66634Z"
        fill="#DC5237"/>
    <path
        d="M8.66671 15.6667H7.33337C5.72004 15.6667 4.83337 14.78 4.83337 13.1667V10.5C4.83337 10.2267 5.06004 10 5.33337 10H10.6667C10.94 10 11.1667 10.2267 11.1667 10.5V13.1667C11.1667 14.78 10.28 15.6667 8.66671 15.6667ZM5.83337 11V13.1667C5.83337 14.22 6.28004 14.6667 7.33337 14.6667H8.66671C9.72004 14.6667 10.1667 14.22 10.1667 13.1667V11H5.83337Z"
        fill="#DC5237"/>
    <path
        d="M12 13.0003H10.6667C10.3933 13.0003 10.1667 12.7737 10.1667 12.5003V11.0003H5.83333V12.5003C5.83333 12.7737 5.60667 13.0003 5.33333 13.0003H4C2.38667 13.0003 1.5 12.1137 1.5 10.5003V7.16699C1.5 5.55366 2.38667 4.66699 4 4.66699H12C13.6133 4.66699 14.5 5.55366 14.5 7.16699V10.5003C14.5 12.1137 13.6133 13.0003 12 13.0003ZM11.1667 12.0003H12C13.0533 12.0003 13.5 11.5537 13.5 10.5003V7.16699C13.5 6.11366 13.0533 5.66699 12 5.66699H4C2.94667 5.66699 2.5 6.11366 2.5 7.16699V10.5003C2.5 11.5537 2.94667 12.0003 4 12.0003H4.83333V10.5003C4.83333 10.227 5.06 10.0003 5.33333 10.0003H10.6667C10.94 10.0003 11.1667 10.227 11.1667 10.5003V12.0003Z"
        fill="#DC5237"/>
    <path
        d="M11.3333 11H4.66663C4.39329 11 4.16663 10.7733 4.16663 10.5C4.16663 10.2267 4.39329 10 4.66663 10H11.3333C11.6066 10 11.8333 10.2267 11.8333 10.5C11.8333 10.7733 11.6066 11 11.3333 11Z"
        fill="#DC5237"/>
    <path
        d="M6.66663 8.33301H4.66663C4.39329 8.33301 4.16663 8.10634 4.16663 7.83301C4.16663 7.55967 4.39329 7.33301 4.66663 7.33301H6.66663C6.93996 7.33301 7.16663 7.55967 7.16663 7.83301C7.16663 8.10634 6.93996 8.33301 6.66663 8.33301Z"
        fill="#DC5237"/>
</svg>


export const searchIcon = <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_132_754)">
        <path
            d="M7.66671 14.4999C3.90004 14.4999 0.833374 11.4333 0.833374 7.66659C0.833374 3.89992 3.90004 0.833252 7.66671 0.833252C11.4334 0.833252 14.5 3.89992 14.5 7.66659C14.5 11.4333 11.4334 14.4999 7.66671 14.4999ZM7.66671 1.83325C4.44671 1.83325 1.83337 4.45325 1.83337 7.66659C1.83337 10.8799 4.44671 13.4999 7.66671 13.4999C10.8867 13.4999 13.5 10.8799 13.5 7.66659C13.5 4.45325 10.8867 1.83325 7.66671 1.83325Z"
            fill="black"/>
        <path
            d="M14.6669 15.1666C14.5402 15.1666 14.4135 15.12 14.3135 15.02L12.9802 13.6866C12.7869 13.4933 12.7869 13.1733 12.9802 12.98C13.1735 12.7866 13.4935 12.7866 13.6869 12.98L15.0202 14.3133C15.2135 14.5066 15.2135 14.8266 15.0202 15.02C14.9202 15.12 14.7935 15.1666 14.6669 15.1666Z"
            fill="black"/>
    </g>
    <defs>
        <clipPath id="clip0_132_754">
            <rect width="16" height="16" fill="white"/>
        </clipPath>
    </defs>
</svg>

export const viewIcon = <svg xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 576 512">
    <path
        d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z"/>
</svg>

export const viewIconWhite = <svg xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 576 512">
    <path stroke="white"
          d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z"
          fill="#ffffff"/>
</svg>


export const editIcon = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M3.69332 13.0137C3.28666 13.0137 2.90666 12.8737 2.63332 12.6137C2.28666 12.287 2.11999 11.7937 2.17999 11.2603L2.42666 9.10033C2.47332 8.69366 2.71999 8.15366 3.00666 7.86033L8.48 2.06703C9.84666 0.620358 11.2733 0.580358 12.72 1.94703C14.1667 3.31369 14.2067 4.74036 12.84 6.18703L7.36666 11.9803C7.08666 12.2803 6.56666 12.5603 6.15999 12.6271L4.01332 12.9937C3.89999 13.0003 3.79999 13.0137 3.69332 13.0137ZM10.62 1.94036C10.1067 1.94036 9.66 2.26036 9.20666 2.74036L3.73332 8.54039C3.59999 8.68039 3.44666 9.01373 3.41999 9.20706L3.17332 11.367C3.14666 11.587 3.19999 11.767 3.31999 11.8803C3.43999 11.9937 3.61999 12.0337 3.83999 12.0003L5.98666 11.6337C6.17999 11.6004 6.49999 11.427 6.63332 11.287L12.1067 5.49369C12.9333 4.61369 13.2333 3.80036 12.0267 2.66703C11.4933 2.15369 11.0333 1.94036 10.62 1.94036Z"
        fill="#FF9500"/>
    <path
        d="M11.5602 7.30019C11.5469 7.30019 11.5269 7.30019 11.5135 7.30019C9.43354 7.09352 7.76021 5.51356 7.44021 3.44689C7.40021 3.17356 7.58688 2.92023 7.86021 2.87356C8.13354 2.83356 8.38688 3.02022 8.43354 3.29356C8.68688 4.90689 9.99354 6.14689 11.6202 6.30689C11.8935 6.33356 12.0935 6.58022 12.0669 6.85352C12.0335 7.10686 11.8135 7.30019 11.5602 7.30019Z"
        fill="#FF9500"/>
    <path
        d="M14 15.167H2C1.72667 15.167 1.5 14.9403 1.5 14.667C1.5 14.3937 1.72667 14.167 2 14.167H14C14.2733 14.167 14.5 14.3937 14.5 14.667C14.5 14.9403 14.2733 15.167 14 15.167Z"
        fill="#FF9500"/>
</svg>


export const editIconWhite = <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
    <path stroke="white"
          d="M3.69332 13.0137C3.28666 13.0137 2.90666 12.8737 2.63332 12.6137C2.28666 12.287 2.11999 11.7937 2.17999 11.2603L2.42666 9.10033C2.47332 8.69366 2.71999 8.15366 3.00666 7.86033L8.48 2.06703C9.84666 0.620358 11.2733 0.580358 12.72 1.94703C14.1667 3.31369 14.2067 4.74036 12.84 6.18703L7.36666 11.9803C7.08666 12.2803 6.56666 12.5603 6.15999 12.6271L4.01332 12.9937C3.89999 13.0003 3.79999 13.0137 3.69332 13.0137ZM10.62 1.94036C10.1067 1.94036 9.66 2.26036 9.20666 2.74036L3.73332 8.54039C3.59999 8.68039 3.44666 9.01373 3.41999 9.20706L3.17332 11.367C3.14666 11.587 3.19999 11.767 3.31999 11.8803C3.43999 11.9937 3.61999 12.0337 3.83999 12.0003L5.98666 11.6337C6.17999 11.6004 6.49999 11.427 6.63332 11.287L12.1067 5.49369C12.9333 4.61369 13.2333 3.80036 12.0267 2.66703C11.4933 2.15369 11.0333 1.94036 10.62 1.94036Z"
          fill="#FF9500"/>
    <path stroke="white"
          d="M11.5602 7.30019C11.5469 7.30019 11.5269 7.30019 11.5135 7.30019C9.43354 7.09352 7.76021 5.51356 7.44021 3.44689C7.40021 3.17356 7.58688 2.92023 7.86021 2.87356C8.13354 2.83356 8.38688 3.02022 8.43354 3.29356C8.68688 4.90689 9.99354 6.14689 11.6202 6.30689C11.8935 6.33356 12.0935 6.58022 12.0669 6.85352C12.0335 7.10686 11.8135 7.30019 11.5602 7.30019Z"
          fill="#FF9500"/>
    <path stroke="white"
          d="M14 15.167H2C1.72667 15.167 1.5 14.9403 1.5 14.667C1.5 14.3937 1.72667 14.167 2 14.167H14C14.2733 14.167 14.5 14.3937 14.5 14.667C14.5 14.9403 14.2733 15.167 14 15.167Z"
          fill="#FF9500"/>
</svg>


export const deleteIcon =<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M13.9998 4.48633C13.9865 4.48633 13.9665 4.48633 13.9465 4.48633C10.4198 4.13299 6.89984 3.99966 3.41315 4.35299L2.05315 4.48633C1.77315 4.51299 1.52649 4.31299 1.49982 4.03299C1.47315 3.75299 1.67316 3.51299 1.94649 3.48633L3.30649 3.35299C6.85318 2.99299 10.4465 3.13299 14.0465 3.48633C14.3198 3.51299 14.5198 3.75966 14.4932 4.03299C14.4732 4.29299 14.2532 4.48633 13.9998 4.48633Z"
        fill="#FF3B30"/>
    <path
        d="M5.66712 3.81301C5.64045 3.81301 5.61379 3.81301 5.58045 3.80634C5.31379 3.75967 5.12712 3.49967 5.17379 3.23301L5.32045 2.35967C5.42712 1.71967 5.57379 0.833008 7.12709 0.833008H8.87376C10.4338 0.833008 10.5804 1.75301 10.6804 2.36634L10.8271 3.23301C10.8738 3.50634 10.6871 3.76634 10.4204 3.80634C10.1471 3.85301 9.88709 3.66634 9.84709 3.39967L9.70043 2.53301C9.60709 1.95301 9.58709 1.83967 8.88043 1.83967H7.13376C6.42712 1.83967 6.41379 1.93301 6.31379 2.52634L6.16045 3.39301C6.12045 3.63967 5.90712 3.81301 5.66712 3.81301Z"
        fill="#FF3B30"/>
    <path
        d="M10.1402 15.1667H5.86022C3.53355 15.1667 3.44022 13.8801 3.36688 12.8401L2.93355 6.12671C2.91355 5.85338 3.12688 5.61338 3.40022 5.59338C3.68022 5.58005 3.91355 5.78672 3.93355 6.06005L4.36688 12.7734C4.44022 13.7867 4.46688 14.1667 5.86022 14.1667H10.1402C11.5402 14.1667 11.5669 13.7867 11.6336 12.7734L12.0669 6.06005C12.0869 5.78672 12.3269 5.58005 12.6002 5.59338C12.8736 5.61338 13.0869 5.84671 13.0669 6.12671L12.6336 12.8401C12.5602 13.8801 12.4669 15.1667 10.1402 15.1667Z"
        fill="#FF3B30"/>
    <path
        d="M9.10673 11.5H6.88673C6.61339 11.5 6.38672 11.2733 6.38672 11C6.38672 10.7267 6.61339 10.5 6.88673 10.5H9.10673C9.38007 10.5 9.60673 10.7267 9.60673 11C9.60673 11.2733 9.38007 11.5 9.10673 11.5Z"
        fill="#FF3B30"/>
    <path
        d="M9.66671 8.83301H6.33337C6.06004 8.83301 5.83337 8.60634 5.83337 8.33301C5.83337 8.05967 6.06004 7.83301 6.33337 7.83301H9.66671C9.94004 7.83301 10.1667 8.05967 10.1667 8.33301C10.1667 8.60634 9.94004 8.83301 9.66671 8.83301Z"
        fill="#FF3B30"/>
</svg>


export const deleteIconWhite =<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path stroke="white"
          d="M13.9998 4.48633C13.9865 4.48633 13.9665 4.48633 13.9465 4.48633C10.4198 4.13299 6.89984 3.99966 3.41315 4.35299L2.05315 4.48633C1.77315 4.51299 1.52649 4.31299 1.49982 4.03299C1.47315 3.75299 1.67316 3.51299 1.94649 3.48633L3.30649 3.35299C6.85318 2.99299 10.4465 3.13299 14.0465 3.48633C14.3198 3.51299 14.5198 3.75966 14.4932 4.03299C14.4732 4.29299 14.2532 4.48633 13.9998 4.48633Z"
          fill="#FF3B30"/>
    <path stroke="white"
          d="M5.66712 3.81301C5.64045 3.81301 5.61379 3.81301 5.58045 3.80634C5.31379 3.75967 5.12712 3.49967 5.17379 3.23301L5.32045 2.35967C5.42712 1.71967 5.57379 0.833008 7.12709 0.833008H8.87376C10.4338 0.833008 10.5804 1.75301 10.6804 2.36634L10.8271 3.23301C10.8738 3.50634 10.6871 3.76634 10.4204 3.80634C10.1471 3.85301 9.88709 3.66634 9.84709 3.39967L9.70043 2.53301C9.60709 1.95301 9.58709 1.83967 8.88043 1.83967H7.13376C6.42712 1.83967 6.41379 1.93301 6.31379 2.52634L6.16045 3.39301C6.12045 3.63967 5.90712 3.81301 5.66712 3.81301Z"
          fill="#FF3B30"/>
    <path stroke="white"
          d="M10.1402 15.1667H5.86022C3.53355 15.1667 3.44022 13.8801 3.36688 12.8401L2.93355 6.12671C2.91355 5.85338 3.12688 5.61338 3.40022 5.59338C3.68022 5.58005 3.91355 5.78672 3.93355 6.06005L4.36688 12.7734C4.44022 13.7867 4.46688 14.1667 5.86022 14.1667H10.1402C11.5402 14.1667 11.5669 13.7867 11.6336 12.7734L12.0669 6.06005C12.0869 5.78672 12.3269 5.58005 12.6002 5.59338C12.8736 5.61338 13.0869 5.84671 13.0669 6.12671L12.6336 12.8401C12.5602 13.8801 12.4669 15.1667 10.1402 15.1667Z"
          fill="#FF3B30"/>
    <path stroke="white"
          d="M9.10673 11.5H6.88673C6.61339 11.5 6.38672 11.2733 6.38672 11C6.38672 10.7267 6.61339 10.5 6.88673 10.5H9.10673C9.38007 10.5 9.60673 10.7267 9.60673 11C9.60673 11.2733 9.38007 11.5 9.10673 11.5Z"
          fill="#FF3B30"/>
    <path stroke="white"
          d="M9.66671 8.83301H6.33337C6.06004 8.83301 5.83337 8.60634 5.83337 8.33301C5.83337 8.05967 6.06004 7.83301 6.33337 7.83301H9.66671C9.94004 7.83301 10.1667 8.05967 10.1667 8.33301C10.1667 8.60634 9.94004 8.83301 9.66671 8.83301Z"
          fill="#FF3B30"/>
</svg>
