import React, { useEffect, useState } from "react";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import close from "../../resources/themes/dashboard-v1/icons/close-icon.svg";
import upload from "../../resources/themes/dashboard-v1/icons/upload.svg";
import NiceInputPassword from "react-nice-input-password";
import "react-nice-input-password/dist/react-nice-input-password.css";
import { Navigate } from "react-router-dom";
import { sendOtp } from "../../services/otpService";
import NewSingleFileUploader from "../NewSingleFileUploader.tsx";
import ErrorMsg from "../ErrorMsg.tsx";

const RepresenterInfo = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumberCC, setPhoneNumberCC] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [governmentId, setgovernmentId] = useState(null);
  const [password, setPassword] = useState("");

  const [saveData, setSaveData] = useState(false);

  useEffect(() => {
    setFirstName(props.registrationData.representerInfo.first_name);
    setLastName(props.registrationData.representerInfo.last_name);
    setEmail(props.registrationData.representerInfo.email);
    setPhoneNumberCC(props.registrationData.representerInfo.phone_number_cc);
    setPhoneNumber(props.registrationData.representerInfo.phone_number);
    if (props.registrationData.representerInfo.government_id)
      setgovernmentId(props.registrationData.representerInfo.government_id);
    setPassword(props.registrationData.representerInfo.password);
  }, []);

  useEffect(() => {
    const formContainer = document.getElementById("form-container");
    if (formContainer) {
      formContainer.scrollTop = 0;
    }
  }, []);

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    governmentId: "",
    password: "",
  });

  const onChangeUploadProduct = (event) => {
    setgovernmentId(event.target.files[0]);
  };

  const clearUploadPhoto = () => {
    setgovernmentId(null);
  };

  const handlePhoneNumberChange = (value) => {
    if (typeof value === "string") {
      const digitCount = (value.match(/\d/g) || []).length;

      if (digitCount > 1) {
        const parsedPhoneNumber = parsePhoneNumber(value);

        if (parsedPhoneNumber) {
          setPhoneNumber(value);
          const phoneNumberCc = parsedPhoneNumber.countryCallingCode;
          setPhoneNumberCC(phoneNumberCc);
        } else {
          setPhoneNumber("");
          setPhoneNumberCC("");
        }
      } else {
        setPhoneNumber("");
        setPhoneNumberCC("");
      }
    } else {
      setPhoneNumber("");
      setPhoneNumberCC("");
    }
  };

  const handlePasswordChange = (data) => {
    setPassword(data.value);
  };

  const isPasswordValid = (password) => {
    const minLength = /(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}/;
    const hasUpperCase = /[A-Z]/;
    const hasNumberOrSpecialChar = /[\d!@#$%&]/;

    return (
      minLength.test(password) &&
      hasUpperCase.test(password) &&
      hasNumberOrSpecialChar.test(password)
    );
  };

  const validate = () => {
    let isValid = true;
    let errors = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      governmentId: "",
      password: "",
    };

    if (firstName.trim() === "") {
      errors.firstName = "first name is required.";
      isValid = false;
    }
    if (lastName.trim() === "") {
      errors.lastName = "last name is required.";
      isValid = false;
    }

    if (email.trim() === "") {
      errors.email = "Email is required.";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Invalid email format.";
      isValid = false;
    }

    if (!phoneNumber) {
      errors.phoneNumber = "Phone number is required.";
      isValid = false;
    }

    if (governmentId == null || governmentId.type !== "application/pdf") {
      errors.governmentId = "Invalid file type. Please upload a PDF document.";
      isValid = false;
    }

    if (password.trim() === "") {
      errors.password = "Password is required.";
      isValid = false;
    } else if (!isPasswordValid(password)) {
      errors.password = "Password does not meet the requirements.";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSendOtp = () => {
    sendOtp(phoneNumber)
      .then((response) => {
        if (response.status === "success") {
          setErrors("");
        } else {
          setErrors("Failed to send OTP");
        }
      })
      .catch((error) => {
        setErrors("Failed to send OTP");
      });
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (validate()) {
      props.setRegistrationData({
        ...props.registrationData,
        representerInfo: {
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone_number_cc: phoneNumberCC,
          phone_number: phoneNumber,
          government_id: governmentId,
          password: password,
        },
      });
      setSaveData(true);
      handleSendOtp();
      props.updateTimeLine(2);
    }
  };

  return (
    <form
      className={"full-width pt-1 p-5"}
      encType="multipart/form-data"
      onSubmit={handleNext}
    >
      <div className={props.class}>
        <h2 className="mb-3 fw-bold">Enter the Representer Info</h2>

        <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
          <label className="fw-semibold">First Name</label>
          <input
            type="text"
            value={firstName}
            placeholder="first name..."
            onChange={(e) => setFirstName(e.target.value)}
          />
          {errors.firstName && (
            <ErrorMsg msg={errors.firstName}/>
          )}
        </div>

        <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
          <label className="fw-semibold">Last Name</label>
          <input
            type="text"
            value={lastName}
            placeholder="last name..."
            onChange={(e) => setLastName(e.target.value)}
          />
          {errors.lastName && (
              <ErrorMsg msg={errors.lastName}/>
          )}
        </div>

        <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
          <label className="fw-semibold">Business Email</label>
          <input
            type="text"
            value={email}
            placeholder="example@gmail.coml"
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && (
              <ErrorMsg msg={errors.email}/>
          )}
        </div>

        <div className="mb-3 position-relative">
          <label className="fw-semibold mb-1">Phone Number</label>

          <PhoneInput
            defaultCountry="AE"
            international
            withCountryCallingCode
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            className={"p-3"}
          />
          {errors.phoneNumber && (
              <ErrorMsg msg={errors.phoneNumber}/>
          )}
        </div>

        <div className="mb-3">
          <NewSingleFileUploader
              label={"Government ID"}
              name={"government_id"}
              defaultFile={props.registrationData.representerInfo.government_id instanceof File ? props.registrationData.representerInfo.government_id : null}
              onFileChange={(file) => {
                setgovernmentId(file);
              }
          }/>
          {errors.governmentId && (
              <ErrorMsg msg={errors.governmentId}/>
          )}
        </div>

        <div className="password-container input-container">
          <NiceInputPassword
              label="Create Password"
              name="passwordField"
              value={password}
              className="w-100"
              securityLevels={[
                {
                  descriptionLabel: "Minimum 8 characters (alphanumeric or special)",
                  validator: /^(?=.*[A-Za-z])(?=.*[0-9!#@$%&])[A-Za-z0-9!#@$%&]{8,}$/,
                },
                {
                  descriptionLabel: "At least one uppercase character",
                  validator: /^(?=.*[A-Z]).*$/,
                },
                {
                  descriptionLabel: "At least one number or special character",
                  validator: /^(?=.*[0-9!#@$%&]).*$/,
                },
                {
                  descriptionLabel: "At least one alphabet character",
                  validator: /^(?=.*[A-Za-z]).*$/,
                }
              ]}
              showSecurityLevelDescription
              onChange={handlePasswordChange}
          />
          {errors.password && (
              <ErrorMsg msg={errors.password}/>
          )}
        </div>

        <button
          type="submit"
          className="btn btn-primary py-2 mt-4 fw-bold w-100"
        >
          Next
        </button>
      </div>
    </form>
  );
};

export default RepresenterInfo;
