const API_HOST = `${process.env.REACT_APP_DASHBOARD_API_URL}`;

const fetch_shop_discounts = () => {
    const url = `${API_HOST}/api/dash/shop/discounts`;

    return fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
}

const delete_shop_discounts = async (ids: number[]) => {
    const url = new URL(`${API_HOST}/api/dash/shop/discounts/delete`);
    url.searchParams.append("ids", ids.join(","));

    const reqInit: RequestInit = {
        method: "DELETE",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
    }
    return fetch(url, reqInit).then((res) => res.json());
};

interface ManageByIDOptions {
    action: "sync" | "link" | "unlink";
    discount_id: number;
    item_ids: number[];
}

const manage_discounted_items_by_ids = (options: ManageByIDOptions) => {
    const url = new URL(`${API_HOST}/api/dash/shop/discounts/manage-by-ids/product`);

    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const rawBody = JSON.stringify({
        "action": options.action,
        "discount_id": options.discount_id,
        "item_ids": options.item_ids,
    });

    const requestOptions: RequestInit = {
        method: "POST",
        credentials: "include",
        headers: headers,
        body: rawBody,
        redirect: "follow"
    };

    return fetch(url, requestOptions)
            .then((response) => response.json())
            .catch((error) => console.error(error));
}


export {
    fetch_shop_discounts,
    delete_shop_discounts,
    manage_discounted_items_by_ids
}