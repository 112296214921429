import React, {useContext, useState} from "react";
import {Navigate} from "react-router-dom";
import {unauthenticate} from "../../services/auth";
import {AuthAction} from "../../reducers/authReducer";
import AuthContext from "../../contexts/AuthContext";
import {LogoutIcon} from "../../resources/themes/dashboard-v1/icons/rebranded/icons.tsx";

const LogoutButton = ({preset}) => {
    const [isLoggedOut, setIsLoggedOut] = useState(false)
    const { dispatch } = useContext(AuthContext);

    const logout = () => {
        unauthenticate().then((response) => {
            setIsLoggedOut(response.status === "ok" || (response.status === "error" && response.type === "auth"))
        })
        dispatch({type: AuthAction.Logout});
    }

    if (isLoggedOut) {
        return <Navigate to={"/login"}/>
    }

    switch (preset) {
        case "sidebar":
            return <li className='list-item d-flex flex-row gap-2 align-items-center p-3 mb-1'
                       onClick={logout}>
                <LogoutIcon width={25} height={25} />
                <p className='m-0 fw-bold'>Log Out</p>
            </li>

        default:
            return (
                <button className={"btn btn-block btn-primary"} onClick={logout}>Logout</button>
            )
    }
}

export default LogoutButton