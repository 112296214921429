import {useContext} from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthContext from "../contexts/AuthContext";

const PrivateRoutes = () => {
  const { authState } = useContext(AuthContext);
  return authState.authenticated === true ? <Outlet /> : <Navigate to={"/login"} />;
};

export default PrivateRoutes;
