import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const LineChart = ({ data, width, height, isView }) => {
  const chartRef = useRef();

  const chartData = data.map((dataPoint) => ({
    x: Date.parse(dataPoint.x),
    y: dataPoint.y,
  }));

  useEffect(() => {
    d3.select(chartRef.current).selectAll("svg").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const margin = { top: 40, right: 40, bottom: 40, left: 40 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const xScale = d3
      .scaleTime()
      .domain([d3.min(chartData, (d) => d.x), d3.max(chartData, (d) => d.x)])
      .range([0, isView ? innerWidth : width]);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(chartData, (d) => d.y)])
      .range([isView ? innerHeight : height, 0]);

    const line = d3
      .line()
      .x((d) => xScale(d.x))
      .y((d) => yScale(d.y));

    if (!isView) line.curve(d3.curveCardinal);

    if (isView) {
      svg
        .append("rect")
        .attr("width", width)
        .attr("height", height)
        .attr("fill", "white")
        .attr("rx", 15)
        .attr("ry", 15)
        .on("mouseenter", function () {
          d3.select(".line-path").attr("stroke", d3.rgb("#0078ff"));
        })
        .on("mouseleave", function () {
          d3.select(".line-path").attr("stroke", "#4339F2");
        });

      const tickCount = 10;
      const tickValues = chartData.map((d) => d.x).slice(0, tickCount);
      const xAxis = d3
        .axisBottom(xScale)
        .tickValues([xScale.domain()[0], ...tickValues])
        .tickFormat(d3.timeFormat("%d-%m-%Y"));

      svg
        .append("g")
        .attr(
          "transform",
          `translate(${margin.left}, ${innerHeight + margin.top})`
        )
        .call(xAxis);

      svg
        .append("g")
        .attr("transform", `translate(${margin.left}, ${margin.top})`)
        .call(d3.axisLeft(yScale).ticks(10));

      svg
        .selectAll(".grid-line-y")
        .data(yScale.ticks(10))
        .enter()
        .append("line")
        .attr("class", "grid-line-y")
        .attr("stroke", "#e6e6e6")
        .attr("stroke-width", 0.8)
        .attr("x1", margin.left)
        .attr("x2", innerWidth + margin.left)
        .attr("y1", (d) => yScale(d) + margin.top)
        .attr("y2", (d) => yScale(d) + margin.top);

      svg
        .selectAll(".grid-line-x")
        .data(xScale.ticks(10))
        .enter()
        .append("line")
        .attr("class", "grid-line-x")
        .attr("stroke", "#e6e6e6")
        .attr("stroke-width", 0.8)
        .attr("x1", (d) => xScale(d) + margin.left + 10)
        .attr("x2", (d) => xScale(d) + margin.left + 10)
        .attr("y1", margin.top)
        .attr("y2", innerHeight + margin.top);
    }

    svg
      .append("path")
      .datum(chartData)
      .attr("class", "line-path")
      .attr(
        "transform",
        isView ? `translate(${margin.left}, ${margin.top})` : ""
      )
      .attr("fill", "none")
      .attr("stroke", isView ? "#4339F2" : "#34C759")
      .attr("stroke-width", isView ? 2 : 3)
      .attr("d", line)
      .on("mouseenter", function () {
        if (isView) {
          d3.select(this).attr("stroke", d3.rgb("#0078ff"));
        }
      });

    // Add data points and labels
    if (isView) {
      const pointGroup = svg
        .append("g")
        .attr("transform", `translate(${margin.left}, ${margin.top})`);

      // Add data points with event handlers
      const points = pointGroup
        .selectAll(".data-point")
        .data(chartData)
        .enter()
        .append("circle")
        .attr("class", "data-point")
        .attr("cx", (d) => xScale(d.x))
        .attr("cy", (d) => yScale(d.y))
        .attr("r", 5)
        .attr("fill", "#DC5237")
        .on("mouseover", function (event, d) {
          const label = labelGroup.select(`#label-${d.x}`);
          label.style("display", "block");
          d3.select(".line-path").attr("stroke", d3.rgb("#0078ff"));
          d3.select(this).attr("fill", d3.rgb("#ff3300"));
        })
        .on("mouseout", function (event, d) {
          const label = labelGroup.select(`#label-${d.x}`);
          label.style("display", "none");
          d3.select(this).attr("fill", d3.rgb("#DC5237"));
        });

      // Add labels for each data point
      const labelGroup = svg
        .append("g")
        .attr("transform", `translate(${margin.left}, ${margin.top + 10})`);

      const labels = labelGroup
        .selectAll(".data-label")
        .data(chartData)
        .enter()
        .append("text")
        .attr("class", "data-label")
        .attr("id", (d) => `label-${d.x}`)
        .attr("x", (d) => xScale(d.x) + 10)
        .attr("y", (d) => yScale(d.y) - 10)
        .text((d) => d.y)
        .style("display", "none");
    }
  }, [chartData, width, height, isView]);

  return <div ref={chartRef}></div>;
};

export default LineChart;
