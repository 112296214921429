// @ts-ignore
import DynamicDataTable from "../../common/DynamicDataTable.tsx";
// @ts-ignore
import React, {useEffect, useState} from "react";
import {Discount, DiscountListingApiResponse} from "../types";
// @ts-ignore
import {fetch_shop_discounts, delete_shop_discounts} from "../../../../services/discounts.ts";
import {Link} from "react-router-dom";
import {Product, ProductListingApiResponse} from "../../shop/products/types";

import {
    editIcon,
    editIconWhite,
    deleteIcon,
    deleteIconWhite
} from "../../../../resources/themes/dashboard-v1/icons/icons";

import { notify_promise } from "../../../../services/utils/toasts";

const Page = () => {
    const [response, setResponse] = useState<DiscountListingApiResponse>(undefined);
    const [pageReadyStatus, setPageReadyStatus] = useState(false);

    // Set Request Options & Fetch results based on URL params whenever they change or are loaded initially.
    useEffect(() => {
        fetch_shop_discounts().then((apiResponse: DiscountListingApiResponse) => {
            apiResponse.fields.push({key: "actions", label: "Actions", type: "actions"})
            setResponse(apiResponse);
            setPageReadyStatus(true);
        })
    }, []);

    if (!pageReadyStatus) {
        return <div className="page-content py-3 px-4 bg-lightgray">
            <div    className={"d-flex flex-column justify-content-center align-middle text-center"}
                    style={{minHeight: 400}}>
                <h5>Loading.. </h5>
            </div>
        </div>
    }

    return <div className="page-content py-3 px-4 bg-lightgray">
        <div className={"d-flex desktop-flex-row justify-content-between page-control-container gap-2 mb-3"}>
            <div className={"d-flex desktop-flex-row gap-2"}>
                <div>
                    <Link to={"/admin/form/discounts"} className={"btn btn-primary p-3"}>
                        + Add New Discount
                    </Link>
                </div>
            </div>
        </div>
        <DynamicDataTable
            fields={response.fields}
            data={response.data}
            pagination={response}
            Actions={(props: ActionsCellProps) => {
                return <Actions row={props.row}
                                setResponse={setResponse}
                />
            }}
        />
    </div>
}

export default Page;


interface ActionsCellProps {
    row: Discount
    setResponse: React.Dispatch<React.SetStateAction<ProductListingApiResponse>>
}

const Actions = (props: ActionsCellProps) => {
    const {row, setResponse} = props;

    return <div className={"d-flex flex-row justify-content-center align-items-center actions-td"}>
        <Link to={`/admin/form/discounts/${row.id}`} className={"btn btn-icon d-flex flex-row justify-content-center align-items-center m-1 btn-icon-light-warning"}>
            <span className="hide-hover-item svg">
                {editIcon}
            </span>
            <span className="hover-item svg">
                {editIconWhite}
            </span>
        </Link>
        <button
            className={"btn btn-icon d-flex flex-row justify-content-center align-items-center m-1 btn-icon-light-danger"}
            onClick={() => {
                deleteDiscount(row.id, setResponse)
            }}
        >
            <span className="hide-hover-item svg">
                {deleteIcon}
            </span>
            <span className="hover-item svg">
                {deleteIconWhite}
            </span>
        </button>
    </div>

}

const deleteDiscount = (id: number, setResponse: React.Dispatch<React.SetStateAction<ProductListingApiResponse>>) => {
    notify_promise(
        new Promise((resolve, reject) => {
            delete_shop_discounts([id])
                .then((res) => {
                    setResponse((prevResponse: ProductListingApiResponse) => ({
                        ...prevResponse, // Spread previous state
                        data: prevResponse.data.filter((p: Product) => p.id !== id),
                    }));
                    resolve(res);
                })
                .catch(reject);
        })
    );
}