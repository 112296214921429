// @ts-ignore
import React, { useEffect, useState } from "react";
// @ts-ignore
import pdf from "../resources/themes/dashboard-v1/icons/pdf.svg";
// @ts-ignore
import { DocumentIcon } from "../resources/themes/dashboard-v1/icons/rebranded/icons.tsx";
// @ts-ignore
import FileObjectImageRenderer from "./FileObjectImageRenderer.tsx";

interface SingleFileUploaderProps {
    name: string;
    label?: string;
    acceptedTypes?: string[];
    onFileChange: (file: File) => void;
    defaultFile?: File | null;
}

const AcceptedImageTypes = ["image/jpeg", "image/png", "image/gif"];
const AcceptedPDFTypes = ["application/pdf"];

const SingleFileUploader: React.FC<SingleFileUploaderProps> = ({
                                                                   name,
                                                                   label,
                                                                   acceptedTypes,
                                                                   onFileChange,
                                                                   defaultFile
                                                               }) => {
    const [file, setFile] = useState<File | null>(null);
    const fileInputRef = React.useRef<HTMLInputElement | null>(null);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || !e.target.files.length) return;
        const newFile = e.target.files[0];
        setFile(newFile);
        onFileChange(newFile);
    };

    const openFileInput = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (fileInputRef.current) {
            // Reset the input value to allow selecting the same file again
            fileInputRef.current.value = "";
            fileInputRef.current.click();
        }
    };

    const handleClearFile = () => {
        setFile(null);
        // Optionally, you might want to reset the input value here as well,
        // although the `openFileInput` function already does this.
    };

    useEffect(() => {
        console.log("default: ", defaultFile)
        if (defaultFile) {
            setFile(defaultFile);
        }
    }, []);

    useEffect(() => {

    }, [file]);

    return (
        <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3 ">
            <label className="fw-semibold">{label ? label : ""}</label>
            <div className="d-flex flex-row gap-2">
                {file?.name && (
                    <div className={"fileIconContainer " + (!file.type.startsWith('image/') ? "doc-preview" : "")}>
                        <WidgetIcon file={file} />
                        <button onClick={handleClearFile}>Clear</button>
                    </div>
                )}
                <div className="uploaded-images-container" style={{ minHeight: "60px" }}>
                    <div className="add-image-button d-flex justify-content-start">
                        <button onClick={openFileInput}>+</button>
                        <input
                            type="file"
                            name={name}
                            id={name + "Upload"}
                            hidden={true}
                            onChange={onChange}
                            accept={acceptedTypes ? acceptedTypes.join(", ") : undefined}
                            ref={fileInputRef}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleFileUploader;

interface WidgetIconProps {
    file: File;
}

const WidgetIcon: React.FC<WidgetIconProps> = ({ file }) => {
    let preview = <div>
        <DocumentIcon width={50} height={50} active={false}/>
        <p>{file?.name.slice(0, 6) + "..."}</p>
    </div>;

    if (file && file.type.startsWith('image/')) {
        preview = <FileObjectImageRenderer file={file} width={60} height={60} alt={file.name}/>;
    }

    return preview
};