import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import upload from "../../resources/themes/dashboard-v1/icons/upload.svg";
import close from "../../resources/themes/dashboard-v1/icons/close-icon.svg";
import camera from "../../resources/themes/dashboard-v1/icons/Camera.svg";
import { Navigate } from "react-router-dom";

import {
  handlePhoneNumberChange,
  handleLandlineChange,
  validate,
} from "./utils/business_info";
import SelectBox from "../dashboard/common/SelectBox";
import { saveRegistrationData } from "../../services/auth";
import SingleFileUploader from "../NewSingleFileUploader.tsx";
import ErrorMsg from "../ErrorMsg.tsx";
import {extractValidationErrors} from "../../utils/api.ts";

const BusinessInfo = (props) => {
  const [shopName, setShopName] = useState("");
  const [businessDescription, setBusinessDescription] = useState("");
  const [phoneNumberCC, setPhoneNumberCC] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [landline, setLandline] = useState("");
  const [landlineCC, setLandlineCC] = useState("");
  const [licenseName, setLicenseName] = useState("");
  const [license, setLicense] = useState(null);
  const [businessLogo, setBusinessLogo] = useState(null);
  const [saveData, setSaveData] = useState(false);
  const [generalErrorMessage, setGeneralErrorMessage] = useState(false);

  const [errors, setErrors] = useState({
    shopName: "",
    businessDescription: "",
    phoneNumber: "",
    licenseName: "",
    license: "",
    businessLogo: "",
    landline: "",
  });

  useEffect(() => {
    const formContainer = document.getElementById("form-container");
    if (formContainer) {
      formContainer.scrollTop = 0;
    }

    setShopName(props.registrationData.businessInfo.shop_name)
    setBusinessDescription(props.registrationData.businessInfo.business_description)
    setPhoneNumberCC(props.registrationData.businessInfo.phone_number_cc)
    setPhoneNumber(props.registrationData.businessInfo.phone_number)
    setLandlineCC(props.registrationData.businessInfo.landline_cc)
    setLandline(props.registrationData.businessInfo.landline)
    setLicenseName(props.registrationData.businessInfo.license_name)
    setLicense(props.registrationData.businessInfo.license)
    setBusinessLogo(props.registrationData.businessInfo.business_logo)
  }, []);

  useEffect(() => {
    setBusinessLogo(businessLogo);
  }, [businessLogo]);

  async function handleSaveRegistration(data) {
    await saveRegistrationData(data)
      .then((response) => {
        if (response.status === "ok") {
          setGeneralErrorMessage("");
          setSaveData(true);
          props.updateTimeLine(4);
        } else {
          props.updateTimeLine(5);
          setGeneralErrorMessage(response.message);
          try {
            props.setErrorMessages(extractValidationErrors(response))
          } catch (error) {
            console.log("error", error)
          }
        }
      })
      .catch((error) => {
        setGeneralErrorMessage(
          "An error occurred while saving registration data."
        );
        props.updateTimeLine(6);
        console.error("Fetch error:", error);
      });
  }

  const handleNext = async (e) => {
    e.preventDefault();

    if (
      validate(
        shopName,
        businessDescription,
        phoneNumber,
        landline,
        licenseName,
        license,
        businessLogo,
        setErrors
      )
    ) {
      const data = await {
        ...props.registrationData,
        businessInfo: {
          shop_name: shopName,
          business_description: businessDescription,
          phone_number_cc: phoneNumberCC,
          phone_number: phoneNumber,
          landline_cc: landlineCC,
          landline: landline,
          business_logo: businessLogo,
          license_name: licenseName,
          license,
        },
      };

      props.setRegistrationData(data);
      handleSaveRegistration(data);
      setSaveData(true);
    }
  };

  return (
    <form
      className={"full-width p-5"}
      encType="multipart/form-data"
      onSubmit={(e) => handleNext(e)}
    >
      <div className={`${props.class} business-info`}>
        <h2 className="mb-2 fw-bold">Business info</h2>

        <div>
          <p className="sec-title">BASIC INFO</p>

          <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
            <label className="fw-semibold">Shop Name</label>
            <input
              type="text"
              value={shopName}
              placeholder="shop name..."
              onChange={(e) => setShopName(e.target.value)}
            />
            {errors.shopName && (
                <ErrorMsg msg={errors.shopName}/>
            )}
          </div>

          <div className="business-logo d-flex flex-column gap-1 input-container mb-md-0 mb-3 position-relative">
            <SingleFileUploader label={"Business Logo"}
                                name={"business_logo"}
                                defaultFile={props.registrationData.businessInfo.business_logo instanceof File ? props.registrationData.businessInfo.business_logo : null}
                                onFileChange={(file) => {
                                  setBusinessLogo(file);
                                }}
            />

            {errors.license !== "" && (
                <ErrorMsg msg={errors.license}/>
            )}
          </div>
          {errors.businessLogo && (
              <ErrorMsg msg={errors.businessLogo}/>
          )}

          <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
            <label className="fw-semibold">Business Description</label>
            <textarea
              type="text"
              value={businessDescription}
              placeholder="Add a text that best describe your business..."
              onChange={(e) => setBusinessDescription(e.target.value)}
            />
            {errors.businessDescription !== "" && (
                <ErrorMsg msg={errors.businessDescription}/>
            )}
          </div>

          <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
            <label className="fw-semibold">License Name</label>
            <input
              type="text"
              value={licenseName}
              placeholder="license name..."
              onChange={(e) => setLicenseName(e.target.value)}
            />
            {errors.licenseName && (
                <ErrorMsg msg={errors.licenseName}/>
            )}
          </div>

          <div className="mb-3">
            <SingleFileUploader label={"Trade License"}
                                name={"trade_license"}
                                defaultFile={props.registrationData.businessInfo.license instanceof File ? props.registrationData.businessInfo.license : null}
                                onFileChange={(file) => {
                                  setLicense(file);
                                }}
            />

            {errors.license !== "" && (
                <ErrorMsg msg={errors.license}/>
            )}
          </div>

          <div className="mb-3 position-relative">
            <label className="fw-semibold mb-1">Phone Number</label>

            <PhoneInput
              defaultCountry="AE"
              international
              withCountryCallingCode
              value={phoneNumber}
              onChange={(value) =>
                handlePhoneNumberChange(value, setPhoneNumber, setPhoneNumberCC)
              }
              className={"p-3"}
            />
            {errors.phoneNumber && (
                <ErrorMsg msg={errors.phoneNumber}/>
            )}
          </div>

          <div className="mb-3 position-relative">
            <label className="fw-semibold mb-1">Landline</label>

            <PhoneInput
              defaultCountry="AE"
              international
              withCountryCallingCode
              value={landline}
              onChange={(value) =>
                handleLandlineChange(value, setLandline, setLandlineCC)
              }
              className={"p-3"}
            />
            {errors.landline && (
                <ErrorMsg msg={errors.landline}/>
            )}
          </div>
        </div>

        <button
          type="submit"
          className="btn btn-primary py-2 mt-4 fw-bold w-100"
        >
          Next
        </button>
      </div>
    </form>
  );
};

export default BusinessInfo;
