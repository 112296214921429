import './resources/themes/dashboard-v1/scss/dashboard.scss'
import './resources/themes/dashboard-v1/scss/dashboard-mobile.scss'

import React, {StrictMode} from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import {AuthProvider} from "./contexts/AuthContext";

// ReactDOM.createRoot(document.getElementById('root')).render(<StrictMode><App/>></StrictMode>);
ReactDOM.createRoot(document.getElementById('root')).render(<>
    <AuthProvider>
        <App/>
    </AuthProvider>
</>);