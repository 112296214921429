import React from 'react';
import dot from '../../resources/themes/dashboard-v1/icons/timeline-dot.svg'
import line from '../../resources/themes/dashboard-v1/icons/timeline-line.svg'
import dotDisabled from '../../resources/themes/dashboard-v1/icons/timeline-dot-disabled.svg'
import lineDisabled from '../../resources/themes/dashboard-v1/icons/timeline-line-disabled.svg'
import success from '../../resources/themes/dashboard-v1/icons/timeline-success.svg'
import ProgressBar from "@ramonak/react-progress-bar";

const TimeLine = ({ index , progress }) => {

    const steps = ['Business Type', 'Business Representer Info', 'OTP Verification', 'Setup your Business']

    return (
        <div className={"time-line-container"}>
            <div className="time-line">

                {
                    steps.map((title, ind) => (
                        <div className='time-line-item-container' key={ind}>
                            <div className='time-line-item'>
                                {ind === index && <div className='dot-container'><img src={dot} alt="dot" /></div>}
                                {ind < index && <div className='dot-container dot-success'><img src={success} alt="dot" /></div>} 
                                {ind > index && <div className='dot-container'><img src={dotDisabled} alt="dot" /></div>} 
                                {ind <= index &&  <p className='desktop-only m-0'>{title}</p>}
                                {ind > index &&  <p className='desktop-only step-title-disabled m-0'>{title}</p>}
                            </div>

                            {ind < index && <img className='line desktop-only' src={line} alt="line" />}
                            {ind >= index && <img className='line desktop-only' src={lineDisabled} alt="line" />}
                            {ind < index && <div className='line-h mobile-only'></div>}
                            {ind >= index && <div className='line-h line-h-disabled mobile-only'></div>}
                        </div>
                    ))
                }
            </div>

            <div className='progress-bar-container'>
                <p className='ms-md-0 ms-2'>{`%${progress} `}<span className='d-md-inline d-none'>Progress</span></p>
                <ProgressBar
                    width='234px'
                    height='8px'
                    completed={progress}
                    isLabelVisible={false}
                    bgColor={'#fff'}
                    baseBgColor={'#FEBEB2'}
                    borderRadius={'12px'}
                />
            </div>
        </div>
    );
}

export default TimeLine;
