import {DynamicField} from "../../types";
// @ts-ignore
import React from "react";
import DataTable, {TableStyles} from "react-data-table-component";
import {Link, useSearchParams} from "react-router-dom";
import {LaravelPagination, TableField} from "../types";

const customStyles: TableStyles = {
    rows: {
        style: {
            minHeight: "100px",
        },
    },
    headCells: {
        style: {
            fontSize: "16px",
            fontWeight: "bold",
            color: "#5A5A5A",
            textAlign: "center",
        },
    },
    cells: {
        style: {
            fontSize: "16px",
            fontWeight: "bold",
            color: "#161616",
        },
    },
};

interface Data {}

interface DynamicDataTableProps {
    fields: TableField[]
    data: Data[]
    pagination: LaravelPagination
    Actions?: React.FC<{row: any}>
}

const DynamicDataTable: React.FC<DynamicDataTableProps> = ({fields, data, pagination, Actions}) => {
    const columns = getDataTableColumnsFromTableFields(fields, Actions)
    return <div>
        <DataTable
            columns={columns}
            data={data}
            customStyles={customStyles}
            striped
            highlightOnHover
            noHeader
            className="rounded-4 table-space"
        />

        <DynamicTablePaginationControls pagination={pagination}/>
    </div>
}
interface TablePaginationControlProps {
    pagination: LaravelPagination
}

const DynamicTablePaginationControls: React.FC<TablePaginationControlProps> = ({ pagination }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <div className={"text-grey mt-5 px-2 text-center"}>
            <div className={"d-flex desktop-flex-row justify-content-between align-items-center w-full gap-3"}>
                {/*Per Page Select Box*/}
                <div className="d-flex desktop-flex-row align-items-center justify-content-center gap-2">
                    <select
                        className="drop-down"
                        defaultValue={searchParams.has("perPage") ? searchParams.get("perPage") : "10"}
                        onChange={(e) => {
                            setSearchParams((params: URLSearchParams) => {
                                params.set('perPage', e.target.value);
                                params.set('page', "1");
                                return params
                            });
                        }}
                    >
                        <option key={5} value="10">10</option>
                        <option key={10} value="20">20</option>
                        <option key={20} value="50">50</option>
                    </select>
                    <p className="light-black fs-12 m-0">Results Per Page</p>
                </div>
                {/*Pagination Links*/}
                <div className="d-flex desktop-flex-row align-items-middle justify-content-center gap-4">
                    <div>
                        <Link to={pagination.first_page_url}
                              className="page-number-btn fw-bold "
                              aria-disabled={!pagination.prev_page_url}>
                            First Page
                        </Link>
                    </div>
                    <div>
                        <Link to={pagination.prev_page_url}
                          className="page-number-btn fw-bold "
                          aria-disabled={!pagination.prev_page_url}>
                        Previous Page
                    </Link>
                    </div>
                    <div>
                        <span
                            className={"page-number-btn fw-bold text-white bg-primary"}>Page {pagination.current_page}</span>
                    </div>
                    <div>
                        <Link to={pagination.next_page_url}
                              className="page-number-btn fw-bold "
                          aria-disabled={!pagination.next_page_url}>
                        Next Page
                    </Link>
                    </div>
                    <div>
                        <Link to={pagination.last_page_url}
                          className="page-number-btn fw-bold "
                          aria-disabled={pagination.current_page === pagination.last_page}>
                        Last Page
                    </Link>
                    </div>
                </div>
                {/*Page Number*/}
                <div>
                    Page {pagination.current_page} / {(Math.ceil(pagination.total / pagination.per_page))}
                </div>
            </div>
        </div>
    );
};


export default DynamicDataTable;

interface DynamicTableCellProps {
    type: string;
    data: any;
}

const DynamicTableCell: React.FC<DynamicTableCellProps> = ({type, data}) => {
    if (type === "thumbnail") {
        return <div className="fw-bold py-4">
            <img
                className={`rounded-4 img-thumbnail`}
                src={data}
                alt={`${type}`}
            />
        </div>
    }


    return <div className="fw-bold py-4">
        {data}
    </div>
}

const getDataTableColumnsFromTableFields = (fields: DynamicField[], Actions?: React.FC<{row: any}>) => {
    return fields.map((field) => {
        return {
            name: field.label,
            selector: (row) => row[field.key],
            sortable: true,
            cell: (row, rowIndex) => {
                if (field.type === "actions" && Actions) {
                    return <Actions row={row} />;
                }

                if (!Object.keys(row).includes(field.key)) {
                    return <div>Missing</div>
                }
                return <DynamicTableCell data={row[field.key]} type={field.type}/>
            },
        };
    });
}