// @ts-ignore
import React, {Key, useEffect, useState} from 'react';
import {AvailableDiscount, Product, ProductReview} from "./types";
// @ts-ignore
import {Discount} from "../../discounts/types.ts"

// @ts-ignore
import starSVG from "../../../../resources/themes/dashboard-v1/icons/star.svg";

import DynamicInput from "../../DynamicInput"
import {DynamicField} from "../../../types";

import { notify_promise } from "../../../../services/utils/toasts";
import {manage_discounted_items_by_ids} from "../../../../services/discounts.ts";
import productModal from "./ProductModal";

type ProductModalProps = {
    id: string;
    product: Product;
    available_discounts?: AvailableDiscount[]
}

const ProductModal: React.FC<ProductModalProps> = ({id, product, available_discounts}) => {

    useEffect(() => {

    }, [product]);

    if (product === undefined) {
        return <Popup id={id}><h3>Loading</h3></Popup>
    }

    const popupTitle = <div>
        <h6>
            Product ID <span className={"text-grey"}>#{product.id}</span>
        </h6>
    </div>

    return <Popup id={id} title={popupTitle}>
        <ProductHeader product={product}/>
        <ul className="nav nav-tabs p-2" id="productTab" role="tablist">
            <li className="nav-item" role="presentation">
                <button className="nav-link active" id="product-reviews" data-bs-toggle="tab" data-bs-target="#home"
                        type="button" role="tab" aria-controls="home" aria-selected="true">Reviews
                </button>
            </li>
            <li className="nav-item" role="presentation">
                <button className="nav-link" id="product-discounts" data-bs-toggle="tab" data-bs-target="#profile"
                        type="button" role="tab" aria-controls="profile" aria-selected="false">Discounts
                </button>
            </li>
        </ul>
        <div className="tab-content overflow-auto max-height-300" id="productTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="product-reviews">
                <ReviewList reviews={product.reviews}/>
            </div>
            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="product-discounts">
                <div className={"text-center p-2"}>

                    {available_discounts !== undefined ?
                        <DiscountSelector productID={product.id} available_discounts={available_discounts} activeDiscount={product.discount}/> :
                        ""
                    }

                    {product.discount !== undefined ?
                        <DiscountOverview product_id={product.id} discount={product.discount} /> :
                        <p>No active discounts for this products</p>
                    }
                </div>
            </div>
        </div>
    </Popup>
}

interface DiscountSelectorProps {
    productID: number
    activeDiscount?: Discount
    available_discounts: AvailableDiscount[]
}

const DiscountSelector: React.FC<DiscountSelectorProps> = ({productID, available_discounts, activeDiscount}) => {
    const [selectedDiscount, setSelectedDiscount] = useState<number>(0)

    const options = [
        {label: "None", value: 0},
    ]

    available_discounts.forEach((discount: AvailableDiscount) => {
        options.push({
            label: discount.name,
            value: discount.id,
        })
    })

    useEffect(() => {
        if (activeDiscount !== undefined) {
            setSelectedDiscount(activeDiscount.id)
            return;
        }
        setSelectedDiscount(0)
    }, [productID])

    return <div className={"d-flex flex-row gap-1 mb-2"}>
        <select
            className={"p-2 flex-grow-1 border-0 bg-gray-light full-width"}
            defaultValue={selectedDiscount}
            value={selectedDiscount}
            onChange={(e) => {setSelectedDiscount(parseInt(e.target.value))}}
        >
            {options.map((option, index) => {
                return <option key={index} value={option.value}>{option.label}</option>
            })}
        </select>
        <div className="d-flex flex-md-row flex-column justify-content-md-end align-items-center gap-3 btn-containers">
            <button
                disabled={selectedDiscount === 0 || (activeDiscount !== undefined && selectedDiscount === activeDiscount.id)}
                className={"btn btn-primary px-5 py-3-50"}
                onClick={() => {
                    notify_promise(
                        new Promise((resolve, reject) => {
                            manage_discounted_items_by_ids({
                                action: "sync",
                                discount_id: selectedDiscount,
                                item_ids: [productID]
                            })
                                .then((res) => {
                                    resolve(res);
                                })
                                .catch(reject);
                        }),
                        "Syncing Applied Discounts",
                    );
                }}>
                Save
            </button>
        </div>
    </div>
}


interface DiscountOverviewProps {
    product_id: number
    discount: Discount
}

const DiscountOverview: React.FC<DiscountOverviewProps> = ({product_id, discount}) => {
    return <div className={"d-flex flex-column border p-2 gap-2"}>

        <div className={"d-flex flex-row gap-3 align-items-center"}>
            <span className={"badge bg-primary rounded-4 px-3"}>{discount.name}</span>
            <div className={"flex-grow-1"}>
                {discount.type === "fixed" ?
                    <p className={"m-0"}>
                        {discount.value} AED less than original price
                    </p> :
                    <p className={"m-0"}>
                        {discount.value} off from original price
                    </p>}
            </div>
            <button
                className={"btn border-0 text-decoration-underline text-danger px-3 py-1"}
                onClick={() => {
                    notify_promise(
                        new Promise((resolve, reject) => {
                            manage_discounted_items_by_ids({
                                action: "unlink",
                                discount_id: discount.id,
                                item_ids: [product_id]
                            })
                                .then((res) => {
                                    resolve(res);
                                })
                                .catch(reject);
                        }),
                        "Discount Un-applied",
                    );
                }}>
                Unapply
            </button>
        </div>
        <div className={"p-2 text-center"}>
            {discount.promo_code !== undefined && discount.promo_code !== "N/A" ?
                <div className={"badge"}>
                    <p className={"bg-primary p-2 m-0"}
                       style={{borderTopRightRadius: "10px", borderTopLeftRadius: "10px"}}>Promo Code</p>
                    <p className={"bg-gray-light text-black p-2 m-0"}>{discount.promo_code}</p>
                </div> :
                <span>No Promo Code Applicable</span>
            }
        </div>
        <div className={"d-flex flex-row justify-content-between"}>
            <p className={"m-0"}>Start Date: {discount.start_date}</p>
            <p className={"m-0 text-grey"}>End Date: {discount.end_date}</p>
        </div>
    </div>
}


interface ProductHeaderProps {
    product: Product
}

const ProductHeader: React.FC<ProductHeaderProps> = ({product}) => {
    return <div className={"p-2"}>
        <div className={"d-flex flex-row"}>
            <img
                className={"img-fluid position-relative d-block"}
                src={product.img_picture} alt={product.name}
                width={120}
                height={100}
            />
            <div className={"flex-grow-1"}>
                <div className={"d-flex flex-row justify-content-between text-muted fw-semibold small"}>
                    <h6 className={""}>{product.brand_name}</h6>
                    <div>
                        ({product.reviews.length} Reviews)
                    </div>
                </div>
                <h5>{product.name}</h5>

                {product.category_text ? <span className={"badge badge-primary"}>{product.category_text}</span> : ""}

                <div>
                    <span className={"fw-bold fs-5 " + (product.discount !== undefined ? " text-decoration-line-through text-grey" : " color-primary")}>
                            {product.price}
                    </span>
                    {product.discount !== undefined ?
                        <span className={"fw-bold color-primary fs-5 "}
                                style={{marginLeft: 10}}>
                            {product.discounted_price}
                        </span>
                    : ""}
                </div>
            </div>
        </div>

    </div>
}

export default ProductModal;

type PopupProps = {
    id: string;
    title?: React.ReactNode;
    children: React.ReactNode;
}

const Popup: React.FC<PopupProps> = ({id, product, title, children}) => {
    return (
        <div className="modal fade" id={id} tabIndex={-1} aria-labelledby={id + "Label"} aria-hidden="true">
            <div className="modal-dialog" style={{maxWidth: 600}}>
                <div className="modal-content" style={{minHeight: 500, borderRadius: "1.4rem"}}>
                    <div className="modal-header border-0">
                        {title ? title : ""}
                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                    </div>
                    <div className="modal-body" style={{textAlign: "left"}}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

interface ReviewListProps {
    reviews: ProductReview[]
}

const ReviewList: React.FC<ReviewListProps> = ({reviews}) => {
    if (reviews.length === 0) {
        return <div className={"text-center p-2"}>No reviews so far</div>
    }

    return <div className={"popup-data-items-container p-4 d-flex flex-column gap-4"}>
        {reviews.map((review: ProductReview, index: Key) => (
            <div
                className={"product-review d-flex gap-4 border-bottom py-4"}
                key={index}
            >
                <div className="">
                    <img
                        width={80}
                        height={120}
                        className={"reviewer-img img-thumbnail"}
                        style={{minWidth: 80}}
                        src={review.pet_owner.picture}
                        alt="user image"
                    />
                    {/*<img src={review.user_image} alt="User Image"/>*/}
                </div>
                <div>
                    <span>
                      {review.pet_owner.first_name +
                          " " +
                          review.pet_owner.last_name}
                    </span>
                    <div className={"d-flex flex-row"}>
                        <img src={starSVG} width={20} alt={"Star"}/>
                        <div className={"m-2"}>{review.rating}</div>
                    </div>
                    <p className={"bold"}>{review.description}</p>
                </div>
            </div>
        ))}
    </div>
}

