const API_HOST = process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/auth/";

export function authenticate(email, password) {
  return fetch(API_HOST + "login", {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      email: email,
      password: password,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((response) => {
      if (response.status === "error") {
        return response;
      }

      return fetch_authed_user();
    })
    .catch((error) => {
      console.error(error);
    });
}

export const unauthenticate = () => {
  return fetch(API_HOST + "logout", {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
  .then((res) => res.json());
};

export const fetch_authed_user = () => {
  return fetch(API_HOST + "user", {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  })
  .then((res) => res.json());
};

export function saveRegistrationData(registrationData) {
  const { representerInfo, businessInfo } = registrationData;

  registrationData.businessInfo.name = businessInfo.shop_name;
  registrationData.businessInfo.description = businessInfo.business_description;

  const formData = new FormData();
  formData.append("license", businessInfo.license);
  formData.append("business_logo", businessInfo.business_logo);
  formData.append("government_id", representerInfo.government_id);

  return fetch(API_HOST + "register", {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(registrationData),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === "ok") {
        return upload_registration_documents(formData).then(
          (uploadResponse) => {
            if (uploadResponse.status === "ok") {
              return {
                status: "ok",
                failed: "none",
              };
            }
            return {
              status: "error",
              failed: "uploads",
            };
          }
        );
      }

      return res;
    })
    .catch((error) => {
      console.error(error);
      return { error };
    });
}

const upload_registration_documents = (formData) => {
  return fetch(API_HOST + "register-documents", {
    method: "POST",
    credentials: "include",
    body: formData,
  })
    .then((res) => res.json())
    .catch((error) => {
      console.error(error);
      return { error };
    });
};

export function resetPassword(password, phoneNumber, phoneNumberCC) {
  return fetch(API_HOST + "reset-password", {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      phone_number_cc: phoneNumberCC,
      phone_number: phoneNumber,
      password: password,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .catch((error) => {
      console.error(error);
      return { error };
    });
}

/**
 * Sends a ping request to an API to check if
 * the user is logged in (since JWT token is in an http-only cookie)
 * @returns Promise<PingApiResponse>
 */
export const ping = () => {
  return fetch(API_HOST + "ping", {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
      .then((res) => res.json())
      .catch((error) => {
        console.error(error);
        return { success: false, message: "An error occurred"};
      });
}