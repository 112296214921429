import logoWhite from '../../resources/themes/dashboard-v1/img/rebranded/logo-white.png';
import iconEnvelope from '../../resources/themes/dashboard-v1/icons/envelope.svg'
import LoginForm from "./LoginForm";
import { Link } from 'react-router-dom';

function LoginPage() {
  return (
    <section className="login-section container-fluid">
      <div className="row full-screen-height">
        <div className="col-md-6 account-left-col flex-container-center full-height">
          <div className="bg-doodles flex-container-center">
            <div className="text-center full-width logo-container">
              <img src={logoWhite} alt="Logo" className="logo" />
            </div>
            <div className="full-width">
              <LoginForm className={"mobile-only p-3 bg-white"} />
            </div>
            <div className="bg-footer flex-container-center">
              <p className="text-white">© Pawpaws 2025</p>
              <a
                className="link-plain text-white email-link"
                href="mailto:help@pawpaws.com"
                target="_blank"
              >
                <img src={iconEnvelope} alt="envelope" />
                <span className={"p-1"}>help@pawpaws.com</span>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-6 desktop-only">
          <div className="flex-container-center flex-column h-100">
            <div className="d-flex justify-content-end w-100">
              <Link to={"/signup"} className="py-3 px-2 join-us">
                <button className="btn btn-primary btn-padded">Join Us</button>
              </Link>
            </div>
            <div className="flex-container-column flex-container-center">
              <LoginForm className={"login-form p-3"} />
              <div>
                <p>
                  Don’t have an account?{" "}
                  <a className="link" href="/signup">
                    Join Pawpaws Now
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginPage;
