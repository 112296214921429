// @ts-ignore
import React, { useContext, useEffect } from "react";
import {Link, useLocation} from "react-router-dom";
import LogoutButton from "./LogoutButton";
// @ts-ignore
import logoBlack from "../../resources/themes/dashboard-v1/img/rebranded/logo-primary.png";
import AuthContext from "../../contexts/AuthContext";

import { circleXIcon } from "../../resources/themes/dashboard-v1/icons/icons";
import { Menu, UserRole } from "../../services/types";

// @ts-ignore
import {DashboardIcon} from "../../resources/themes/dashboard-v1/icons/rebranded/icons.tsx";

const CaretIcon = () => {
    return (
        <svg
            className="icon ms-4 pe-none caret"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.99975 5.29993C8.46642 5.29993 8.93308 5.47993 9.28641 5.83326L13.6331 10.1799C13.8264 10.3733 13.8264 10.6933 13.6331 10.8866C13.4397 11.08 13.1197 11.08 12.9264 10.8866L8.57975 6.54001C8.25975 6.22001 7.73975 6.22001 7.41975 6.54001L3.0731 10.8866C2.87977 11.08 2.55977 11.08 2.36644 10.8866C2.1731 10.6933 2.1731 10.3733 2.36644 10.1799L6.71308 5.83326C7.06642 5.47993 7.53308 5.29993 7.99975 5.29993Z"
                fill="#A0A0A0"
            />
        </svg>
    );
};

// Regular menu item component
const MenuItem = ({ menu, isSelected }: {
    menu: Menu,
    isSelected: boolean,
}) => (
    <Link to={menu.link}>
        <li
            className={`${isSelected ? "selected-listitem" : ""} list-item d-flex align-items-center p-2 mb-1`}
        >
            <DashboardIcon title={menu.title} active={isSelected} />
            <p className="m-0 fw-bold pe-none">{menu.title}</p>
        </li>
    </Link>
);

// Dropdown menu component for both role-based and regular submenus
const DropdownMenu = ({
                          title,
                          targetId,
                          items,
                          currentPath
                      }: {
    title: string,
    targetId: string,
    items: Menu[],
    linkKey?: string,
    currentPath: string,
}) => {
    return <div>
        <li
            className="list-item d-flex flex-row justify-content-between collapsed align-items-center py-sm-3 p-2 mb-1"
            data-bs-toggle="collapse"
            data-bs-target={`#${targetId}`}
        >
            <div className={"d-flex flex-row align-items-center"}>
                <DashboardIcon title={title} active={false}/>
                <p className="m-0 fw-bold">
                    {title}
                </p>
            </div>
            <CaretIcon/>
        </li>
        <div
            className="collapse submenu submenu-items"
            id={targetId}
        >
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small ps-3">
                {items.map((item, subIndex) => (
                    <MenuItem
                        isSelected={item.link === currentPath}
                        menu={item}
                    />
                ))}
            </ul>
        </div>
    </div>
};

interface OrganizedMenuData {
    roles: Record<string, Menu[]>
    common: Menu[]
}

function organizeMenusByRole(menus: Menu[], isAdmin: boolean) : OrganizedMenuData {
    const currentPath = window.location.pathname;

    // Filter out settings menu if user is admin
    menus = menus.filter(menu =>
        !(menu.title === "Settings" && menu.submenu && isAdmin)
    );

    const roleGroups: OrganizedMenuData = {
        roles: {},
        common: []
    };

    menus.forEach(menu => {
        if (menu.link === currentPath) {
            menu.active = true
        }
        if (menu.role) {
            if (!roleGroups.roles[menu.role]) {
                roleGroups.roles[menu.role] = [];
            }
            roleGroups.roles[menu.role].push(menu);
        } else {
            roleGroups.common.push(menu);
        }
    });

    return roleGroups;
}

const DashboardSideBar: React.FC = () => {
    // @ts-ignore
    const { authState } = useContext<AuthState>(AuthContext);
    const location = useLocation()

    let menusItems = authState.data.menus;
    const userIsAdmin = authState.data.user.roles.some((role: UserRole) => role.name === "super-admin");
    // Organize menus by role
    const organizedMenus = organizeMenusByRole(menusItems, userIsAdmin);

    useEffect(() => {
    }, [authState]);

    const closeSideBar = (): void => {
        const sidebar = document.getElementById("dashboard-sidebar");
        sidebar?.classList.toggle("open");
    };

    const defaultHomeLink: Menu = {
        title: "Dashboard",
        link: "/admin",
        order: 0
    }

    return (
        <div id="dashboard-sidebar" className="bg-white dashboard-sidebar no-print">
            <div className="d-flex justify-content-end mobile-only menu-close-container">
                <span onClick={closeSideBar}>{circleXIcon}</span>
            </div>

            <div className="dashboard-logo p-4 d-flex justify-content-center">
                <img width={200} src={logoBlack} alt="App Logo" />
            </div>

            <ul className="no-print bg-white px-4 flex-1 pt-3">
                <MenuItem
                    menu={defaultHomeLink}
                    isSelected={defaultHomeLink.link === location.pathname}
                />

                {Object.entries(organizedMenus.roles).map(([role, menus]) => {
                    if (Object.keys(organizedMenus.roles).length > 1) {
                        return <DropdownMenu
                            key={role}
                            title={role}
                            targetId={`${role}-collapse`}
                            items={menus}
                            currentPath={location.pathname}
                        />
                    }

                    return menus.map((menu: Menu, index: number) => {
                        return <MenuItem
                            key={`${menu.role}-${menu.title}`}
                            menu={menu}
                            isSelected={menu.link === location.pathname}
                        />
                    })
                })}

                {organizedMenus.common.map((menu, index) => {
                    return menu.submenu ?
                        <DropdownMenu
                            key={menu.role}
                            title={menu.title}
                            targetId={`${menu.role}-collapse`}
                            items={menu.submenu}
                            currentPath={location.pathname}
                        /> : <MenuItem
                            key={`${menu.role}-${menu.title}`}
                            menu={menu}
                            isSelected={menu.link === location.pathname}
                        />

                })}

                <LogoutButton preset="sidebar" />
            </ul>
        </div>
    );
};

export default DashboardSideBar;