import LoginPage from "./components/login/LoginPage";
import { BrowserRouter, createBrowserRouter, Route, RouterProvider, Routes } from "react-router-dom";
import ErrorPage404 from "./components/errors/ErrorPage404";
import PrivateRoutes from "./components/PrivateRoutes";
import Home from "./components/dashboard/home";
import ResetPasswordPage from "./components/resetPassword/ResetPasswordPage";
import SignupPage from "./components/signUp/signupPage";
import SignupDataPage from "./components/signUp/signupDataPage";
import React from "react";
import {ToastContainer} from "react-toastify";

const App = () => {

  return (
        <BrowserRouter>
            <ToastContainer />

            <Routes>
                <Route path={"/login"} element={<LoginPage />} />
                <Route path={"/resetPassword/*"} element={<ResetPasswordPage />} />
                <Route path={"/signup"} element={<SignupPage />} />
                <Route path={"/signupData/*"} element={<SignupDataPage />} />

                <Route path='*' element={<ErrorPage404 />} />

                <Route element={<PrivateRoutes />}>
                    <Route path={"/"} element={<Home />} />
                    <Route path={"/admin/*"} element={<Home />} />
                </Route>
            </Routes>
        </BrowserRouter>
  );
}

export default App;
